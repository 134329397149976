// third-party libraries
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Combobox, ComboboxButton, ComboboxOptions, ComboboxOption } from "@headlessui/react";

// icons
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import CheckIcon from "@icons/check-circle.svg?react";
import CircleIcon from "@icons/circle.svg?react";

export type DashboardDoctor = {
    id: string;
    name: string;
};

type DoctorMultiSelectProps = {
    doctors: DashboardDoctor[];
    selectedDoctorIds: string[];
    setSelectedDoctorIds: (doctorIds: string[]) => void;
};

export const UNASSIGNED_DOCTOR_ID = "unassigned";
const ALL_DOCTORS_SELECTION = "alldoctors";

export const getLabelFromDoctorId = (doctorId: string, doctors: DashboardDoctor[]) => {
    if (doctorId === UNASSIGNED_DOCTOR_ID) {
        return "Unassigned";
    }

    const doctor = doctors?.find((doctor: DashboardDoctor) => doctor.id === doctorId);
    return doctor?.name || doctorId;
}

export const DoctorMultiSelect = (props: DoctorMultiSelectProps) => {
    const { doctors, selectedDoctorIds, setSelectedDoctorIds } = props;

    // STATE FOR ALL DOCS SELECTED
    // state for whether all docs are selected
    const [allDocsSelected, setAllDocsSelected] = useState(false);

    // update allDocsSelected when selectedDoctorIds changes
    useEffect(() => {
        // if all doctors are selected, including unassigned, then set allDocsSelected to true
        if (selectedDoctorIds.length === doctors.length + 1) {
            setAllDocsSelected(true);
        } else {
            // otherwise, set allDocsSelected to false
            setAllDocsSelected(false);
        }
    }, [selectedDoctorIds, doctors]);


    // COMBOBOX TEXT 
    // state for the combobox placeholder text
    const [comboboxPlaceholderText, setComboboxPlaceholderText] = useState("");

    // update combobox placeholder text when selectedDoctorIds changes
    useEffect(() => {
        const filtered = selectedDoctorIds.filter((id) => id !== UNASSIGNED_DOCTOR_ID);

        if (filtered.length === 1) {
            setComboboxPlaceholderText(getLabelFromDoctorId(filtered[0], doctors));
        } else if (filtered.length === doctors.length) {
            setComboboxPlaceholderText("All Doctors Selected");
        } else if (filtered.length > 1) {
            setComboboxPlaceholderText(`${filtered.length} Doctors Selected`);
        } else {
            setComboboxPlaceholderText("Select Doctors");
        }
    }, [selectedDoctorIds, doctors]);


    // HELPER FUNCTIONS AND TRIGGERS
    /*
    * A function that handles the selection change of the combobox.
    */
    const handleSelectionChange = (newSelection: string[]) => {
        // if all doctors button is selected
        if (newSelection.includes(ALL_DOCTORS_SELECTION)) {
            // if all docs are already selected, deselect all (select only unassigned)
            if (allDocsSelected) {
                setSelectedDoctorIds([UNASSIGNED_DOCTOR_ID]);
            } else {
                // otherwise, select all doctors including unassigned
                setSelectedDoctorIds([...doctors.map((doc) => doc.id), UNASSIGNED_DOCTOR_ID]);
            }
        } else {
            const isAllDoctorsSelected = selectedDoctorIds.length === doctors.length; // All doctors + unassigned
            const updatedSelection = newSelection.filter((id) => id !== ALL_DOCTORS_SELECTION && id !== UNASSIGNED_DOCTOR_ID);

            if (isAllDoctorsSelected) {
                // if all doctors were selected, and an individual doctor is unselected, remove "All Doctors" and unassigned
                setSelectedDoctorIds(updatedSelection);
            } else if (updatedSelection.length === 0) {
                // if no doctor is selected, default to only UNASSIGNED_DOCTOR_ID
                setSelectedDoctorIds([UNASSIGNED_DOCTOR_ID]);
            } else if (updatedSelection.length === doctors.length) {
                // if all doctors are selected, add unassigned
                setSelectedDoctorIds([...updatedSelection, UNASSIGNED_DOCTOR_ID]);
            } else {
                // otherwise, just update the selection
                setSelectedDoctorIds(updatedSelection);
            }
        }
    };

    return (
        <div className="w-full min-w-[180px]">
            <Combobox as="div" value={selectedDoctorIds} onChange={handleSelectionChange} multiple>
                <div className="relative">
                    <div className="relative">
                        <ComboboxButton
                            className={classNames(
                                "w-[213px] text-left rounded-lg border-0 bg-white py-1.5 pl-3 pr-10 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-max-700 text-xs leading-tight sm:text-sm sm:leading-6",
                                selectedDoctorIds.length === 0 && "placeholder:text-gray-400"
                            )}
                        >
                            <span className="text-gray-900 font-semibold">
                                {comboboxPlaceholderText}
                            </span>
                        </ComboboxButton>
                        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                            <ChevronDownIcon className="h-5 w-5 text-gray-900" aria-hidden="true" />
                        </ComboboxButton>
                    </div>

                    {doctors.length > 0 && (
                        <ComboboxOptions className="absolute z-50 mt-1 w-[250px] bg-white shadow-lg ring-1 ring-gray-900 ring-opacity-5 focus:outline-none sm:text-sm rounded-lg">
                            <div className="max-h-60 overflow-auto">
                                {doctors.map((doctor, idx) => (
                                    <ComboboxOption
                                        key={doctor.id}
                                        value={doctor.id}
                                        className={
                                            ({ focus }) => classNames(
                                                "flex flex-row relative cursor-default select-none py-2 pl-4 pr-9",
                                                focus && "bg-gray-100",
                                                idx === 0 && "rounded-t-lg",
                                            )
                                        }
                                    >
                                        {({ selected }) => (
                                            <>
                                                <span className="block truncate font-semibold">{doctor.name}</span>
                                                {selected ? (
                                                    <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-max-700">
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : (
                                                    <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-gray-400">
                                                        <CircleIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    </ComboboxOption>
                                ))}
                            </div>
                            <div className="border-t border-gray-300">
                                <ComboboxOption
                                    key="all-docs-selection"
                                    value={ALL_DOCTORS_SELECTION}
                                    className={
                                        ({ focus }) => classNames(
                                            "flex flex-row relative justify-end cursor-default select-none py-2 pl-4 pr-9 rounded-b-lg",
                                            focus && "bg-gray-100"
                                        )
                                    }
                                >
                                    {() => (
                                        <div className="p-2">
                                            <span className="block truncate mr-3 font-semibold">All Doctors</span>
                                            <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                                {allDocsSelected ? (
                                                    <CheckIcon className="h-5 w-5 text-max-700" aria-hidden="true" />
                                                ) : (
                                                    <CircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                )}
                                            </span>
                                        </div>
                                    )}
                                </ComboboxOption>
                            </div>
                        </ComboboxOptions>
                    )}
                </div>
            </Combobox>
        </div>
    );
};

