// external dependencies
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

// internal data providers
import { useAudioRecordingProvider, RecordingWidgetState } from "@/state/providers/RecordingProvider";

// internal components
import WaitingView from "./WaitingView";
import RecordingView from "./RecordingView";
import UploadView from "./UploadView";
import { toast } from "react-toastify";

const RecordingWidget = () => {
    // app state
    const location = useLocation();

    // internal component state
    const [uploadProgress, setUploadProgress] = useState(0);
    const [audioTime, setAudioTime] = useState("0:00");
    const [newPatientId, setNewPatientId] = useState<string | null>(null);
    const previousLocation = useRef(location.pathname)

    // make size of recording stop button pulse when recording
    const [pulseScale, setPulseScale] = useState(1); // Default scale for stop recording button

    // a utility function for converting seconds to a string
    // returns seconds in 0:00 format
    const secondsToString = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    }

    // ------------------------------
    // PROVIDERS, DATA, AND STATE

    /**
     * Audio Recording Provider
     * hook for managing widget state, audio recording, file upload, document select, patient data
    **/
    const {
        // audio recording controls
        recorderControls: {
            audioStream,
            recordingTime,
            recordingMessage,
        },
        // recording widget controls
        recordingWidgetControls: {
            recordingWidgetState,
            setRecordingWidgetState,
            setRenderWidget,
            renderWidget,
            handleFinishRecording,
        },
        // patient data controls
        recordingPatientControls: {
            patientRecordId,
        },
        // file upload controls
        recordingFileUploadControls: {
            uploadProgress: uploadProviderProgress,
            isUploading,
            uploadMessage,
        },
    } = useAudioRecordingProvider();

    // ------------------------------
    // WIDGET STATE HANDLERS

    // useEffect to determine which pages the widget should be rendered on
    useEffect(() => {
        // if the user changes pages, stop the recording
        if (location.pathname !== previousLocation.current && (recordingWidgetState === RecordingWidgetState.RECORDING || recordingWidgetState === RecordingWidgetState.PAUSED)) {
            handleFinishRecording();
            setRecordingWidgetState(RecordingWidgetState.WAITING);
            toast.warning("Recording stopped because you navigated away from the page");
        }

        // reset the widget state when navigating
        if (location.pathname === "/") {
            setRecordingWidgetState(RecordingWidgetState.WAITING);
        }

        // only default render the widget on the home page
        if (location.pathname === "/" && !renderWidget) {
            setRenderWidget(true);
        } else if (location.pathname !== "/" && renderWidget) {
            setRenderWidget(false);
        }

        // store the previous location
        previousLocation.current = location.pathname;

    }, [location.pathname]);


    // sets the audio time string when recording time changes
    useEffect(() => {
        if (recordingTime >= 0) {
            setAudioTime(secondsToString(recordingTime));
        }
    }, [recordingTime]);

    // transition widget state to SAVED after file is uploaded
    useEffect(() => {
        // if widget is uploading, set upload progress as it changes
        if (recordingWidgetState === RecordingWidgetState.UPLOADING && isUploading) {
            setUploadProgress(uploadProviderProgress);
        }

        // if widget is uploading and upload is complete, set state to SAVED
        if (recordingWidgetState === RecordingWidgetState.UPLOADING && !isUploading) {
            setUploadProgress(100);
            setRecordingWidgetState(RecordingWidgetState.SAVED);
        }
    }, [recordingWidgetState, isUploading, uploadProviderProgress]);

    // transition widget state to WAITING and clear progress bar after 3 seconds of SAVED
    useEffect(() => {
        // if widget is saved, set a timeout to transition back to waiting
        if (recordingWidgetState === RecordingWidgetState.SAVED) {
            const timeout = setTimeout(() => {
                setRecordingWidgetState(RecordingWidgetState.WAITING);

                // close the widget if not on the home page
                if (location.pathname !== "/" && renderWidget) {
                    setRenderWidget(false);
                }
            }, 3000);

            // cleanup by clearing timeout and progress bar
            return () => {
                clearTimeout(timeout);
                setUploadProgress(0);
                setNewPatientId(null);
            };
        }
    }, [recordingWidgetState, location.pathname, renderWidget]);

    useEffect(() => {
        if (!audioStream || recordingWidgetState === RecordingWidgetState.PAUSED) return;

        // create audio context and analyser
        const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 256;

        // connect audio stream to analyser
        const source = audioContext.createMediaStreamSource(audioStream);
        source.connect(analyser);

        // store frequency data in an array
        const dataArray = new Uint8Array(analyser.frequencyBinCount);

        const updateScale = () => {
            analyser.getByteFrequencyData(dataArray);

            // calculate average volume
            const avgVolume =
                dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length;

            // map volume to scale factor (adjust min/max as needed)
            const scale = (avgVolume / 255) * 1; // normalize between 0.0 and 1.0
            setPulseScale(scale);

            requestAnimationFrame(updateScale);
        };

        updateScale();

        return () => {
            source.disconnect();
            analyser.disconnect();
            audioContext.close();
        };
    }, [audioStream, recordingWidgetState]);

    // store new patient id when recording is saved
    useEffect(() => {
        if (patientRecordId) {
            setNewPatientId(patientRecordId);
        }
    }, [patientRecordId]);

    // ------------------------------
    // VIEWS AND RENDERING

    // render component state based on widget state
    const recordingWidget = () => {
        switch (recordingWidgetState) {
            case RecordingWidgetState.WAITING:
                return <WaitingView />;
            case RecordingWidgetState.RECORDING:
                return <RecordingView pulseScale={pulseScale} audioTime={audioTime} />;
            case RecordingWidgetState.PAUSED:
                return <RecordingView pulseScale={pulseScale} audioTime={audioTime} />;
            case RecordingWidgetState.UPLOADING:
                return <UploadView uploadProgress={uploadProgress} newPatientId={newPatientId} setNewPatientId={setNewPatientId} />;
            case RecordingWidgetState.SAVED:
                return <UploadView uploadProgress={uploadProgress} newPatientId={newPatientId} setNewPatientId={setNewPatientId} />;
        };
    }

    const widgetHeight = () => {
        // default height
        const DEFAULT_HEIGHT = "h-[129px]";

        switch (recordingWidgetState) {
            // waiting state
            case RecordingWidgetState.WAITING:
                return DEFAULT_HEIGHT;

            // expand widget during recording
            case RecordingWidgetState.RECORDING:
            case RecordingWidgetState.PAUSED:
                if (recordingMessage.current) return "h-[435px]";

                return "h-[395px]";

            // shrink widget during upload view
            case RecordingWidgetState.UPLOADING:
            case RecordingWidgetState.SAVED: {
                // if there was an upload error or warning, expand the widget
                return uploadMessage ? "h-[162px]" : DEFAULT_HEIGHT;
            }

            default:
                return DEFAULT_HEIGHT;
        }

    }

    // render the recording widget
    // note we manage widget height transitions here
    return renderWidget ? (
        <div
            className={`w-[270px] right-[70px] sm:w-[360px] sm:right-24 fixed bottom-5 bg-shadow-900 rounded-lg shadow flex flex-col items-center gap-4 z-50
                ${widgetHeight()} transition-all duration-500 ease-in-out`}
        >
            {recordingWidget()}
        </div>
    ) : null;
};

export default RecordingWidget;
