import { useState, useCallback } from "react";
import { AddPatientRequest, BroadcastEvents, PatientRecord } from "@common/utils/types";
import { insertPatientRecord, setDate, updatePatientRecord } from "@redux/patientTableRecordsSlice";
import { Doctor } from "@common/utils/types";
import { isSameDay, isSameWeek } from "@common/utils/helpers";
import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";
import { useDataContextApi } from "@providers/DataProvider";
import { useRealtimeContextApi } from "@/state/providers/RealtimeProvider";

const usePatientState = (patientTableRecord: PatientRecord | null = null) => {
	const dispatch = useAppDispatch();
	const { selectedDoctor } = useAppSelector((state) => state.doctors);
	const { view } = useAppSelector((state) => state.patientTableRecords);
	const { selectedDate, setSelectedDate } = useDataContextApi();
	const { emitEvent } = useRealtimeContextApi()
	// State variables
	const [patientName, setPatientName] = useState("");
	//NOTE: PatientId is a separate field associated PMS patient ids. Don't confuse it with the patient record id
	const [patientId, setPatientId] = useState("");
	const [scheduledAtDay, setScheduledAtDay] = useState(formatDate());
	const [scheduledAtTime, setScheduledAtTime] = useState(getCurrentTimeRounded());
	const [appointmentDuration, setAppointmentDuration] = useState(Number(localStorage.getItem("appointmentDuration")) || 30);
	const [localSelectedDoctor, setLocalSelectedDoctor] = useState<Doctor | null>(selectedDoctor);

	// Clear Fields
	// partial clear: just clear the patient name and id
	// full clear: clear doctor, scheduled at day and time
	// default to full clear (important for quickstart)
	const clearFields = useCallback((howMuch: 'partial' | 'full' = 'full') => {
		// partial clear: just clear the patient name and id
		setPatientName("");
		setPatientId("");

		// full clear: clear doctor, scheduled at day and time
		if (howMuch === 'full') {
			setLocalSelectedDoctor(null);

			setScheduledAtDay(formatDate());
			setScheduledAtTime(getCurrentTimeRounded());
		}
	}, []);

	function calculateNextAvailableTime(startTime: string, duration: number) {
		const [hours, minutes] = startTime.split(":").map(Number);
		const startTimeDate = new Date();
		startTimeDate.setHours(hours, minutes, 0, 0); // set the date object to current appointment time

		// add the duration to get the end time of the current appointment
		startTimeDate.setMinutes(startTimeDate.getMinutes() + duration);

		// format the next available time
		const nextHours = startTimeDate.getHours().toString().padStart(2, "0");
		const nextMinutes = startTimeDate.getMinutes().toString().padStart(2, "0");

		return `${nextHours}:${nextMinutes}`;
	}

	function formatDate(date = new Date()) {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const day = String(date.getDate()).padStart(2, "0");
		return `${year}-${month}-${day}`;
	}

	function getCurrentTimeRounded(date = new Date(), isQuickStart = false) {
		let minutes = date.getMinutes();
		let hours = date.getHours();

		if (isQuickStart) {
			// Round to the nearest 15-minute block within the current hour
			const roundedMinutes = Math.round(minutes / 15) * 15;
			minutes = roundedMinutes % 60;
			if (roundedMinutes >= 60) {
				// If rounding up exceeds 60 minutes, increment the hour
				// Also, ensure hours wrap around at 24
				hours = (hours + 1) % 24;
			}
		} else {
			// Round up to the next 15-minute block
			const m = 15;
			const roundedMinutes = Math.ceil(minutes / m) * m;
			minutes = roundedMinutes % 60;
			if (roundedMinutes >= 60) {
				// If rounding up exceeds 60 minutes, increment the hour
				// Also, ensure hours wrap around at 24
				hours = (hours + 1) % 24;
			}
		}

		const formattedHours = hours.toString().padStart(2, "0");
		const formattedMinutes = minutes.toString().padStart(2, "0");

		return `${formattedHours}:${formattedMinutes}`;
	}

	//Shallow Copy.
	const createNewUpdatedTableRecord = (patientTableRecord: PatientRecord) => {
		const scheduledAt = scheduledAtDay + "T" + scheduledAtTime + ":00";

		let updatedPatientRecord: PatientRecord = {
			...patientTableRecord,
			doctors: localSelectedDoctor,
			patient_id: patientId,
			patient_name: patientName,
			appointment_duration: appointmentDuration,
			scheduled_at: new Date(scheduledAt).toISOString(),
		};
		return updatedPatientRecord;
	};

	//Handle insert and update patient record
	const handleInsertPatientRecord = async (isQuickStart: boolean = false, patientNameArg: string | undefined = undefined) => {
		let scheduledAt = scheduledAtDay + "T" + scheduledAtTime + ":00";

		//If Quick start, we round to the nearest 15 minute increment.
		if (isQuickStart) {
			let updatedTime = getCurrentTimeRounded(new Date(), true);
			scheduledAt = scheduledAtDay + "T" + updatedTime + ":00";
		}

		if (!selectedDate?.startDate) return null;

		//Add if we have the same day, same doctor (or week, depending on the view)
		let isTodayOrWeek = view === 'day' ?
			isSameDay(new Date(scheduledAt), selectedDate.startDate) :
			isSameWeek(new Date(scheduledAt), selectedDate.startDate);

		let newPatientRecord: AddPatientRequest = {
			doctorId: localSelectedDoctor?.id || selectedDoctor?.id,
			patientName: patientNameArg || patientName,
			patientId,
			scheduledAt: new Date(scheduledAt).toISOString(),
			appointmentDuration: appointmentDuration,
		}

		const data = await dispatch(insertPatientRecord({ newPatientRecord, isTodayOrWeek })).unwrap()

		// not sure if we want this - may cause duplicate events to be added to state
		if (isTodayOrWeek && data) {
			// emit the patient event
			await emitEvent(BroadcastEvents.patientEvent, { patientRecordId: data.id });
		}

		// if we are not adding to the table, navigate to the correct date
		if (!isTodayOrWeek) {
			// navigate to the correct date
			setSelectedDate({ startDate: new Date(), endDate: null });
			dispatch(setDate(new Date().toISOString()))
		}

		return data
	};

	const handleUpdatePatientRecord = async (patientRecord?: PatientRecord) => {

		let updatedTableRecord: PatientRecord | undefined = patientRecord;

		if (!patientRecord) {
			updatedTableRecord = createNewUpdatedTableRecord(patientTableRecord!);
		}

		if (!updatedTableRecord) return

		if (!selectedDate?.startDate) return

		let updateTable = view === 'day' ?
			isSameDay(new Date(updatedTableRecord?.scheduled_at || ""), selectedDate.startDate) :
			isSameWeek(new Date(updatedTableRecord?.scheduled_at || ""), selectedDate.startDate);

		await dispatch(updatePatientRecord({ updatedTableRecord, updateTable }) as any);

		if (updateTable) {
			// emit the patient event
			await emitEvent(BroadcastEvents.patientEvent, { patientRecordId: updatedTableRecord.id });
		}
	};

	return {
		patientName,
		setPatientName,
		patientId,
		setPatientId,
		scheduledAtDay,
		setScheduledAtDay,
		scheduledAtTime,
		setScheduledAtTime,
		appointmentDuration,
		setAppointmentDuration,
		clearFields,
		handleInsertPatientRecord,
		localSelectedDoctor,
		setLocalSelectedDoctor,
		formatDate,
		calculateNextAvailableTime,
		handleUpdatePatientRecord,
	};
};

export default usePatientState;
