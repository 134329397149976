import { useSession } from "@supabase/auth-helpers-react";
import classNames from "classnames";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { TranscriptSectionIndexDB } from "../../classes/audioDb";
import useFileUpload from "../../hooks/useFileUpload";
import { uploadAndTranscribeBulkAudio } from "../../lib/supabaseClient";
import { useState } from "react";
import AudioDb from "../../classes/audioDb";
import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";
import { selectTableRecordById, updateTranscriptSectionStatus } from "@redux/patientTableRecordsSlice";
import RefreshIcon from "@icons/refresh-cw-03.svg?react";
import * as Sentry from "@sentry/react";
import { TranscriptSectionStatus } from "@/common/utils/types";
import Spinner from "../Spinner";
import { useDataContextApi } from "@/state/providers/DataProvider";

const UploadModalRow = ({
	upload
}: {
	upload: TranscriptSectionIndexDB;
}) => {
	const { uploadAudio } = useFileUpload();
	const session = useSession();
	const { setIncompleteUploads } = useDataContextApi();

	//TODO: Rip out when we know upload data contains everything to recreate a patient record row
	const currentPatientRecord = useAppSelector((state) => selectTableRecordById(state, upload?.patientRecordId || ""));
	const { selectedDocuments } = useAppSelector((state) => state.documents);
	const dispatch = useAppDispatch();

	const [isUploadingLocal, setIsUploadingLocal] = useState(false);

	const handleUpload = useCallback(
		async (upload: TranscriptSectionIndexDB) => {
			try {
				setIsUploadingLocal(true);
				let uploadError = null;
				const localAudioDb = AudioDb.getInstance();

				Sentry.addBreadcrumb({
					message: "Retrying upload",
					level: "info",
					data: {
						userId: session?.user.id,
						email: session?.user.email,
						patientName: upload?.patientName,
						patientRecordId: upload?.patientRecordId,
						transcriptSectionId: upload.transcriptSectionId
					},
				});

				let patientRecord = currentPatientRecord;
				if (!patientRecord) {
					patientRecord = {
						id: upload.patientRecordId,
						status: TranscriptSectionStatus.InProgress,
						transcriptions: {
							id: upload.transcriptId,
						},
					}
				}

				const audioFile = new File([upload.audioData], upload.fileName, { type: upload.audioData.type });
				let { err, is409 } = await uploadAudio(
					audioFile,
					upload.fileName,
					upload.transcriptId,
					upload.transcriptSectionId,
					patientRecord,
				);
				uploadError = err;

				if (is409) {
					toast.info(`${upload.fileName} is already attempting to upload. Try again later!`);
					setIsUploadingLocal(false);
					return;
				} // If the upload is trying
				if (uploadError) {

					Sentry.addBreadcrumb({
						message: "Error uploading, retrying with uploadAndTranscribeBulkAudio",
						level: "error",
						data: {
							userId: session?.user.id,
							email: session?.user.email,
							patientName: upload?.patientName,
							patientRecordId: upload?.patientRecordId,
						},
					});

					const { error: error2 } = await uploadAndTranscribeBulkAudio(
						upload.transcriptId,
						upload.transcriptSectionId,
						upload.audioData,
						upload.fileName,
						upload.patientRecordId,
						upload?.patientName || "",
						"",
						selectedDocuments
					);
					if (error2) {
						throw error2;
					}
				}

				// success, rm from local db, update state
				await localAudioDb.deleteTranscriptSectionAudio(upload.transcriptId, upload.transcriptSectionId);

				// rm the err from patient row
				dispatch(updateTranscriptSectionStatus({ id: upload.patientRecordId, sectionId: upload.transcriptSectionId, status: TranscriptSectionStatus.InProgress }));
				setIncompleteUploads((prev) => prev.filter((prevUpload) => prevUpload.fileName !== upload.fileName));

				toast.success(`Successfully uploaded ${upload.fileName}`);

				setIsUploadingLocal(false);

			} catch (error) {
				toast.error(`Uh Oh, something went wrong! ${upload.fileName}`);
				Sentry.captureException(error);

				setIsUploadingLocal(false);
			}
		},
		[uploadAudio, session?.user.id],
	);

	return (
		<div className="flex-row space-x-4">
			{isUploadingLocal ? (
				<button
					className={classNames(
						"w-full rounded-md border border-gray-300 flex items-center justify-center text-center gap-2 bg-white px-3.5 py-1.5 text-sm font-semibold text-gray-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-max-700")}
				>
					<Spinner size="xsmall" color="text-gray-900" />
					<span>Loading</span>
				</button>

			) : (
				<button
					className={classNames(
						"w-full rounded-md flex items-center justify-center text-center gap-2 bg-max-700 px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-max-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bg-max-700 focus-visible:outline-max-700")}
					onClick={() => handleUpload(upload)}
				>
					<RefreshIcon className="h-4 w-4 text-white font-semibold" />
					<span>Retry</span>
				</button>
			)}
		</div>
	);
};

export default UploadModalRow;
