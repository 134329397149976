import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

export function useNetworkStatus() {
	const [isOnline, setIsOnline] = useState(true);

	useEffect(() => {
		const goOnline = () => setIsOnline(navigator.onLine);
		const goOffline = () => {
			setIsOnline(false);
			// Call the global toast function directly
			toast.error("Uh oh! We're detecting some network instability!! Please double check your internet");

			Sentry.addBreadcrumb({
				category: "network",
				message: "Network instability detected",
				level: 'info',
				data: {
					isOnline: navigator.onLine,
				}
			});

		};

		window.addEventListener("online", goOnline);
		window.addEventListener("offline", goOffline);

		return () => {
			window.removeEventListener("online", goOnline);
			window.removeEventListener("offline", goOffline);
		};
	}, []);

	return isOnline;
}
