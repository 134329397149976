import HDCheckBox from '@/common/components/HDCheckBox';
import { useAppDispatch, useAppSelector } from '@/common/hooks/useRedux';
import { BroadcastEvents, DocumentType } from '@/common/utils/types';
import { useRealtimeContextApi } from '@/state/providers/RealtimeProvider';
import { addDocumentForDoctor, removeDocumentForDoctor } from '@/state/redux/documentSlice';
import { Combobox } from '@headlessui/react';
import { useEffect, useRef, useCallback, useState } from 'react';

const WidgetDocumentSelect: React.FC = () => {
    const firstSelectedDocRef = useRef<HTMLDivElement>(null);
    const hasAutoScrolled = useRef(false);

    const dispatch = useAppDispatch();

    const { selectedDocumentsRef, emitEvent } = useRealtimeContextApi();
    const { selectedDocuments: selectedDocumentIds, documentTypes, hiddenDocuments } = useAppSelector((state) => state.documents);
    const { selectedDoctor } = useAppSelector((state) => state.doctors)

    const [filteredDocs, setFilteredDocs] = useState<DocumentType[]>(documentTypes);

    useEffect(() => {
        // filter out hidden documents and inactive documents
        const visibleDocs = documentTypes.filter((doc: DocumentType) => !hiddenDocuments.includes(doc?.id || "") && doc?.is_active);

        // separate selected documents and others
        const selectedDocs = visibleDocs.filter((doc: DocumentType) => selectedDocumentIds.includes(doc?.id || ""));
        const otherDocs = visibleDocs.filter((doc: DocumentType) => !selectedDocumentIds.includes(doc?.id || ""));

        // sort both arrays
        selectedDocs.sort((a, b) => a.document_type_name.localeCompare(b.document_type_name));
        otherDocs.sort((a, b) => a.document_type_name.localeCompare(b.document_type_name));

        // combine the arrays with selected documents pinned at the start
        setFilteredDocs([...selectedDocs, ...otherDocs]);
    }, [documentTypes, hiddenDocuments, selectedDocumentIds]);

    // handle setting selected documents
    const handleSetSelectedDocuments = useCallback(async (documentIds: string[]) => {
        // get the ids of the new documents
        const newIds = documentIds.filter((id) => id !== null && id !== undefined);

        // determine which documents need to be added or removed
        const documentsToAdd = newIds.filter((id) => !selectedDocumentIds.includes(id));
        const documentsToRemove = selectedDocumentIds.filter((id: string) => !newIds.includes(id));

        // add new documents
        for (const id of documentsToAdd) {
            await dispatch(addDocumentForDoctor({ documentTypeId: id }));

            if (selectedDoctor) {
                await emitEvent(BroadcastEvents.documentSelection, { documentTypeId: id, remove: false, doctorId: selectedDoctor?.id });
            }

        }

        // remove deselected documents
        for (const id of documentsToRemove) {
            await dispatch(removeDocumentForDoctor({ documentTypeId: id }));

            if (selectedDoctor) {
                await emitEvent(BroadcastEvents.documentSelection, { documentTypeId: id, remove: true, doctorId: selectedDoctor?.id });
            }
        }

        // update the selected documents ref and component state
        selectedDocumentsRef.current = newIds;
    }, [dispatch, selectedDocumentIds, selectedDocumentsRef]);

    // scroll to the first selected document
    useEffect(() => {
        // if we have already auto-scrolled, don't do it again
        if (hasAutoScrolled.current) return

        const timeout = setTimeout(() => {
            if (firstSelectedDocRef.current) {
                firstSelectedDocRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
            hasAutoScrolled.current = true;
        }, 200);

        return () => {
            clearTimeout(timeout);
        }
    }, [selectedDocumentIds]);

    // find the index of the first selected document in filteredDocs
    const firstSelectedIndex = filteredDocs.findIndex((doc) => selectedDocumentIds.includes(doc.id));

    return (
        <div className="w-full h-[204px] bg-shadow-950">
            <div className="w-full h-[34px] bg-shadow-800 self-stretch pl-6 text-white text-xs font-normal flex flex-row items-center">
                <div>
                    SELECTED DOCUMENTS ({selectedDocumentIds.length})
                </div>
            </div>
            <div className="px-6">
                <Combobox
                    value={selectedDocumentIds}
                    onChange={() => {
                    }}
                    multiple
                >
                    <div className="relative w-full text-white max-h-[170px] overflow-auto">
                        {filteredDocs.map((doc, index) => {
                            const docIsSelected = selectedDocumentIds.includes(doc.id);
                            return (
                                <div
                                    key={doc.id}
                                    ref={index === firstSelectedIndex ? firstSelectedDocRef : null}
                                    className="flex flex-row items-center gap-3 cursor-pointer"
                                    onClick={() => {
                                        if (docIsSelected) {
                                            handleSetSelectedDocuments(selectedDocumentIds.filter((item) => item !== doc.id));
                                        } else {
                                            handleSetSelectedDocuments([...selectedDocumentIds, doc.id]);
                                        }
                                    }}
                                >
                                    <div>
                                        <HDCheckBox isChecked={docIsSelected} />
                                    </div>
                                    <div className="py-2.5 text-white text-sm font-normal leading-tight">
                                        {doc.document_type_name}
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </Combobox>
            </div>
        </div>
    );
};

export default WidgetDocumentSelect;