import { createClient } from "@supabase/supabase-js";
import {
	CreatorPromptField,
	Doctor,
	PatientRecord,
	ChatCompletions,
	Transcript,
	TranscriptSection,
	TranscriptSectionStatus,
	AccountIntegration,
	DocumentType,
	PatientRecordFetchErrors,
	SectionType,
	DocumentStatus,
	ExamplePrompt,
	CreatorPromptFieldCategory,
	CustomFieldMeta,
	AppVersion,
	Account,
	Subscription,
	PartialDocumentType,
	Address,
	AccountMetaData,
	VisitPage,
	DataMapping,
} from "../utils/types";
import isUUID from "validator/lib/isUUID";
import isDate from "validator/lib/isDate";
import { invalidateDocumentCache, transcribeAudio } from "./api";
import { VITE_PUBLIC_SUPABASE_URL, VITE_PUBLIC_SUPABASE_ANON_KEY } from "@common/utils/constants";
import { v4 as uuidv4 } from "uuid";
import * as Sentry from "@sentry/react";
import { Database } from "../utils/database.types";
import { InsightsMetric } from "@/features/dashboard/components/metrics/MetricComponent";

const supabaseUrl = VITE_PUBLIC_SUPABASE_URL;
const supabaseAnonKey = VITE_PUBLIC_SUPABASE_ANON_KEY;


/**
 * Supabase response types -----------------------------
 */
export type SupabaseResponse<T> =
	| { data: T; error: null }
	| { data: null; error: Error };


export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey);

// Error handling wrapper function
export async function retryWrapper<T>(
	func: () => Promise<{ data: T | null; error: any }>
): Promise<{ data: T | null; error: any }> {
	let retries = 0;
	const maxRetries = 3;
	let delay = 100; // Initial delay in ms

	while (retries < maxRetries) {
		try {
			const { data, error } = await func();

			if (error) {
				// Handle AbortError (code 20), PGRST116 (no rows returned), and fetch aborts
				if (error?.code === '20' || error?.code === "PGRST116" || (error?.message && error?.message?.includes('AbortError'))) {
					// Do nothing if error is due to aborting the fetch
					return { data: null, error: null };
				}

				// Handle JWT expired
				if (error?.message && isUnAuthorizedError(error)) {
					// Try to refresh session
					const { error: refreshError } = await supabase.auth.refreshSession();
					if (refreshError) {
						// Can't refresh session, redirect to login
						await supabase.auth.signOut();
						window.location.href = '/';
						return { data: null, error };
					} else {
						// Session refreshed, retry the operation
						continue;
					}
				}

				// Handle supabase network errors
				if (error?.message && isNetworkError(error)) {
					// Retry with exponential backoff
					throw error; // Throw to catch block
				}

				// For other errors, log and rethrow
				if (isFatalError(error)) {
					Sentry.captureException(error);
					return { data: null, error };
				}
			}

			// If no error, return data
			return { data, error: null };
		} catch (error) {
			retries++;
			if (retries >= maxRetries) {
				// Max retries reached
				Sentry.captureException(error);
				return { data: null, error };
			}
			// Exponential backoff
			await new Promise((res) => setTimeout(res, delay));
			delay *= 2; // Exponential backoff
		}
	}
	return { data: null, error: null };
}

function isNetworkError(error: any): boolean {
	return error?.message?.toLowerCase().includes('failed to fetch')
		|| error?.message?.toLowerCase().includes('load failed')
		|| error?.message?.toLowerCase().includes('network error')
		|| error?.message?.toLowerCase().includes('timeout')
		|| error?.message?.toLowerCase().includes('abort')
		|| error?.message?.toLowerCase().includes("network request failed")
}

function isUnAuthorizedError(error: any): boolean {
	return (
		error?.message?.toLowerCase().includes("unauthorized") ||
		error?.message?.toLowerCase().includes("jwt expired") ||
		error?.message?.toLowerCase().includes("jwt invalid") ||
		error?.message?.toLowerCase().includes("jwt malformed") ||
		error?.message?.toLowerCase().includes("jwt expired") ||
		error?.message?.toLowerCase().includes("401") ||
		error?.message?.toLowerCase().includes("403")
	);
}

function isFatalError(error: any): boolean {
	// Define fatal errors
	// For example, if error code is not network related
	const nonFatalErrors = ['NetworkError', 'TimeoutError'];
	if (nonFatalErrors.includes(error.code)) {
		return false;
	}
	return true;
}



// APP VERSIONS
export const getAppVersion = async (signal: AbortSignal): Promise<AppVersion | null> => {

	const { data, error } = await retryWrapper(async () => await supabase.from("app_versions").select("id, version").abortSignal(signal).eq("id", 1).single());

	if (error) {
		return null;
	}

	return data as unknown as AppVersion || null;

};


// DOCTORS
// Fetching all doctors
export const getSupabaseDoctors = async (abortSignal: AbortSignal): Promise<Doctor[]> => {
	const { data, error } = await retryWrapper<Doctor[]>(async () => {
		return await supabase
			.from('doctors')
			.select(`
				id, 
				doctor_name, 
				created_at, 
				email, 
				email_confirmed_at,
				confirmation_sent_at,
				account_id,
				sort_index,
				doctor_document_types (document_type_id, doctor_id)
				`)
			.abortSignal(abortSignal)
			.order('created_at', { ascending: true });
	});

	if (error) {
		return [];
	}

	return data || [];
};

export const getSupabaseDoctorById = async (id: string): Promise<Doctor | null> => {
	const { data, error } = await retryWrapper<Doctor>(async () => {
		return await supabase
			.from('doctors')
			.select(`
				id, 
				doctor_name, 
				created_at, 
				email, 
				account_id,
				email_confirmed_at,
				confirmation_sent_at,
				sort_index,
				doctor_document_types (document_type_id, doctor_id)
				`)
			.eq('id', id)
			.single();
	});

	if (error) {
		return null;
	}

	return data || null;
}

// Adding a new doctor
export const addDoctorToSupabase = async (doctor: Partial<Doctor>): Promise<string | null> => {
	doctor.account_id
	const { data, error } = await retryWrapper(async () => {
		const { count } = await supabase.from('doctors')
			.select('*', { count: 'exact' })
			.eq('account_id', doctor.account_id as string);
		return await supabase.from('doctors').insert({
			...doctor,
			sort_index: count ?? 0,
		}).select();
	});

	if (error) {
		return null;
	}

	return data?.[0]?.id || null;
};

// Deleting a doctor
export const deleteDoctorInSupabase = async (id: string): Promise<void> => {
	const { data, error } = await retryWrapper(async () => {
		return await supabase.from('doctors').delete().eq('id', id);
	});

	if (error) {
		return;
	}
};

// Updating a doctor's information
export const updateDoctorInSupabase = async (
	id: string,
	updatedDoctor: Partial<Doctor>
): Promise<Doctor | null> => {
	const { data, error } = await retryWrapper(async () => {
		return await supabase.from('doctors').update(updatedDoctor).eq('id', id).select()
	});

	if (error) {
		return null;
	}

	return data?.[0] as Doctor || null;
};

// SELECTED DOCUMENTS

export const addSelectedDocumentForDoctor = async (
	doctorId: string,
	documentTypeId: string
): Promise<any | null> => {
	const { data, error } = await retryWrapper(async () => {
		return await supabase
			.from('doctor_document_types')
			.insert({ doctor_id: doctorId, document_type_id: documentTypeId })
			.select()
	});

	if (error) {
		if (error?.message?.includes("duplicate key value violates unique constraint")) {
			return null;
		}
		return error;
	}

	return null
};


export const removeSelectedDocumentForDoctor = async (
	doctorId: string,
	documentTypeId: string
): Promise<any | null> => {
	const { data, error } = await retryWrapper(async () => {
		return await supabase
			.from('doctor_document_types')
			.delete()
			.eq('doctor_id', doctorId)
			.eq('document_type_id', documentTypeId);
	});

	if (error) {
		return error;
	}

	return null
};


// DOCUMENT TYPES
export const getSupabaseDocumentTypes = async (abortSignal: AbortSignal, hiddenDocIDs: string[], accountID: string): Promise<DocumentType[]> => {

	if (!accountID) {
		return [];
	}

	const { data, error } = await retryWrapper(async () => {
		return await supabase
			.from("document_types")
			.select(
				`id,
			 document_type_name,
			 account_id,
			 auto_generate,
			 is_default,
			 description,
			 last_updated_at,
			 is_archived,
			 prompt_components,
			 training_data,
			 is_active,
			 custom_document_type,
			 creator_prompt_components,
			 is_json,
			 document_foundation_id
			`,
			)
			.eq('account_id', accountID)
			.abortSignal(abortSignal)
			.filter("is_archived", "eq", false)
	});

	if (error || !data) {
		return [];
	}

	const sharedDocuments = await getSharedDocuments(abortSignal, accountID);
	// if it is hidden, set is_active to false
	sharedDocuments.forEach((doc: any) => {
		if (hiddenDocIDs.includes(doc.id)) {
			doc.is_active = false;
		}
	})

	// combine the user's documents with the shared documents
	// @ts-ignore because there's a type mismatch between DocumentType and what supabase returns
	const allDocs = data.concat(sharedDocuments).filter(doc => doc.id !== undefined) as DocumentType[];

	return allDocs || [];
};

export const getSharedDocuments = async (signal: AbortSignal, accountID: string): Promise<DocumentType[]> => {

	const { data, error } = await retryWrapper(async () => {
		return await supabase
			.from("document_sharing")
			.select(
				`
		document_types (
			id,
			account_id,
			document_type_name,
			auto_generate,
			is_default,
			description,
			last_updated_at,
			is_archived,
			prompt_components,
			training_data,
			is_active,
			custom_document_type,
			creator_prompt_components,
			is_json,
			document_foundation_id,
			is_archived
		)
		`,
			)
			.abortSignal(signal)
			.not("owner_account_id", "eq", accountID)
			.or(`target_account_id.is.null,target_account_id.eq.${accountID}`)
			.filter("can_generate", "eq", true)
			.filter("document_types.is_archived", "eq", false)
	});

	if (error || !data) {
		return [];
	}

	const sharedDocs = data.map((doc) => doc.document_types).filter(doc => doc) as DocumentType[];

	return sharedDocs || [];
}

export const getDocumentTemplates = async (signal: AbortSignal, accountID: string): Promise<DocumentType[]> => {

	const { data, error } = await retryWrapper(async () => {
		return await supabase
			.from("document_sharing")
			.select(
				`
			document_types (
				id,
				account_id,
				document_type_name,
				auto_generate,
				is_default,
				description,
				last_updated_at,
				is_archived,
				prompt_components,
				training_data,
				is_active,
				custom_document_type,
				creator_prompt_components,
				is_json,
				document_foundation_id
			)
			`,
			)
			.abortSignal(signal)
			.or(`target_account_id.is.null,target_account_id.eq.${accountID}`)
			.filter("can_clone", "eq", true)
	});

	if (error) {
		return [];
	}

	const templates = data?.map((doc: any) => doc.document_types).filter(doc => doc) as DocumentType[];

	return templates || [];
};

// retrieves the ids of all shared documents that the user owns
export const getSharedDocIDs = async (signal: AbortSignal, ownerAccountID: string): Promise<string[]> => {

	const { data, error } = await retryWrapper(async () => {
		return await supabase
			.from("document_sharing")
			.select(
				`
			document_types (
				id
			)
			`,
			)
			.abortSignal(signal)
			.eq("owner_account_id", ownerAccountID)
	});

	if (error) {
		return [];
	}

	const ownedSharedDocIds = data?.map((doc: any) => doc.document_types?.id) as string[];

	return ownedSharedDocIds || [];
};

export const getDocumentTypesByIdsList = async (ids: string[]): Promise<PartialDocumentType[]> => {
	const { data, error } = await retryWrapper(async () => {
		return await supabase
			.from("document_types")
			.select(
				`id,
				is_archived,
				document_type_name,
			 	is_json`,
			)
			.in("id", ids);
	});

	if (error) {
		return [];
	}

	return data || [];
};

// Fetching a single document type
export const getSingleSupabaseDocumentType = async (
	id: string,
	abortSignal: AbortSignal
): Promise<DocumentType | null> => {
	const { data, error } = await retryWrapper<DocumentType>(async () => {
		return await supabase
			.from('document_types')
			.select(
				`
		  id, 
		  account_id,
		  document_type_name, 
		  prompt, 
		  prompt_components, 
		  output_parser, 
		  prompt_override, 
		  training_data, 
		  creator_prompt_components, 
		  custom_document_type, 
		  description, 
		  is_archived,
		  is_json, 
		  is_default,
		  document_foundation_id,
		  document_exports ( 
			is_active 
		  )
		`
			)
			.abortSignal(abortSignal)
			.eq('id', id)
			.maybeSingle();
	});

	if (error) {
		// Error is already logged in the wrapper
		return null;
	}

	return data || null;
};


export const toggleDocumentSupabase = async (id: string, isActive: boolean) => {
	let { data, error } = await retryWrapper(async () => {
		return await supabase.from("document_types").update({ is_active: isActive }).eq("id", id);
	});

	return error;
};

// used in autosave
export const saveNewDocumentType = async (documentType: Partial<DocumentType>): Promise<DocumentType | null> => {
	let { error } = await supabase.from("document_types").insert(documentType as any)

	if (error) {
		return null
	}

	return documentType as DocumentType || null

};

// used in autosave
export const updateDocumentType = async (documentType: Partial<DocumentType>, isJson = true): Promise<boolean> => {
	if (!documentType.id) return false

	let { error } = await supabase.from("document_types").update(documentType as any).eq("id", documentType.id as string)

	if (error) {
		return false
	}

	if (!isJson) {
		await invalidateDocumentCache(documentType.id || "");
		return true
	}

	return true
};

export const updateDocumentTypeInclusion = async (docTypeId: string, isActive: boolean): Promise<boolean> => {
	const { error } = await retryWrapper(async () => {
		return await supabase.from("document_types").update({ is_active: isActive }).eq("id", docTypeId)
	});

	if (error) {
		return false;
	}

	return true;
};


export const deleteDocumentFromSupabase = async (id: any): Promise<boolean> => {
	// set is_archived to true instead of deleting
	let { error } = await retryWrapper(async () => {
		return await supabase.from("document_types").update({ is_archived: true }).eq("id", id);
	});

	if (error) {
		return false
	}

	return true
};


//PATIENT RECORDS
export const upsertPatientRecord = async (patientRecord: PatientRecord, transcriptionId: string | null = null): Promise<PatientRecord | null> => {

	const { data, error } = await retryWrapper(async () => {
		return await supabase.from("patient_record").upsert({
			...patientRecord as any,
		}).select()
	});

	if (error) {
		return null
	}

	if (transcriptionId) {
		await upsertTranscription({
			id: transcriptionId,
			patient_record_id: patientRecord?.id,
			doctor_id: patientRecord?.doctor_id,
		});
	}

	return data?.[0] as PatientRecord || null
};

export const updatePatientRecord = async (patientRecord: PatientRecord, id: string): Promise<{ data: PatientRecord | null, error: any | null }> => {

	const { data, error } = await retryWrapper(async () => {
		return await supabase
			.from("patient_record")
			.update({ ...patientRecord as any })
			.eq("id", id)
			.select()
	});

	if (error) {
		return { data: null, error: error };
	}

	return { data: data?.[0] as PatientRecord || null, error: null };
}


export const fetchPatientRecord = async (patient_record_id: string): Promise<{ data: PatientRecord | null, error: any | null }> => {
	// check that patient_record_id is actually a uuid and not null
	if (!patient_record_id || !isUUID(patient_record_id)) {
		return { data: null, error: PatientRecordFetchErrors.NO_PATIENT_RECORD };
	}

	const { data, error } = await retryWrapper(async () => {
		return await supabase
			.from("patient_record")
			.select(
				`
            id,
            patient_name,
            patient_id,
			contact_id,
            created_at,
            doctors (
                id,
                doctor_name,
				pms_employee_id,
				pms_export_id
            ),
            status,
			linked_by_integration,
			scheduled_at,
			stage,
			chat_completions (
				id,
				created_at,
				role,
				type,
				chat_json,
				document_snapshot,
				transcription_id,
				document_type_id,
				patient_record_id,
				feedback,
				exported,
				status,
				header,
				document_types (
					id,
					is_json,
					creator_prompt_components,
					document_type_name,
					document_exports (
						id,
						is_active
					)
				)
			),
			transcriptions (
                id,
				transcript_sections (
					id,
					unprocessed_filename,
					processed_filename,
					data,
					created_at,
					audio_duration,
					status
				)
          	),
			patients (
				id,
				name,
				secondary_id
			),
			account_integrations (
				id,
				connected_third_party,
				is_active,
				integrations (
					integration_name
				)
			),
			contacts (
				id,
				first_name,
				last_name
			)
            `,
			)
			.eq("id", patient_record_id)
			.eq("is_archived", false)
			.eq("chat_completions.is_archived", false)
	});

	if (data?.length === 0) {
		// patient record not found
		// this happens if a user deletes a patient record then hits the back button
		return { data: null, error: PatientRecordFetchErrors.NO_PATIENT_RECORD };
	}

	if (error) {
		return { data: null, error: PatientRecordFetchErrors.FETCH_FAILED };
	}

	// sort transcription sections by created_at ascending to get correct chronological order
	const patientRecord = data?.[0] as PatientRecord;
	if (patientRecord.transcriptions) {
		if ((patientRecord.transcriptions as any).transcript_sections) {
			(patientRecord.transcriptions as any).transcript_sections.sort((a: TranscriptSection, b: TranscriptSection) => {
				return new Date(a.created_at as string).getTime() - new Date(b.created_at as string).getTime();
			});
		}
	}

	return { data: patientRecord, error: null };
};

export const fetchPatientRecords = async (
	selectedDate: any,
	selectedDoctorId: string | null,
	abortSignal: AbortSignal,
): Promise<{ data: PatientRecord[], error: any | null }> => {
	// check selectedDate.startDate is a valid date
	if (selectedDate?.["startDate"] && !isDate(selectedDate?.["startDate"])) {
		return { data: [], error: "Invalid start date" };
	}

	// Format selected date to start at 00:00:00
	// Format selected date to start at 00:00:00 in local time
	let queryStartDate = new Date(selectedDate?.["startDate"]);
	queryStartDate.setHours(0, 0, 0, 0);
	let queryStartDateString = queryStartDate.toISOString();

	// Format selected date to end at 23:59:59 in local time
	let queryEndDate = new Date(selectedDate?.["startDate"]);
	if (selectedDate?.["endDate"] && isDate(selectedDate?.["endDate"])) {
		queryEndDate = new Date(selectedDate?.["endDate"]);
	}
	queryEndDate.setHours(23, 59, 59, 999);
	let queryEndDateString = queryEndDate.toISOString();

	// Start with base query
	//create query for patient records, with transcriptions that have the same patient record id

	let query = supabase
		.from("patient_record")
		.select(
			`
        id,
		  	appointment_duration,
		  	scheduled_at,
        patient_name,
        patient_id,
        created_at,
		  	status,
		  	stage,
        doctors (
          id,
          doctor_name
        ),
        chat_completions (
					id
		  	),
        transcriptions (
          id,
					transcript_sections (
						id,
						unprocessed_filename,
						processed_filename,
						status
					)
    		)
			`,
		)
		.abortSignal(abortSignal)
		.order("scheduled_at", { ascending: true })
		.gte("scheduled_at", queryStartDateString)
		.lte("scheduled_at", queryEndDateString)
		.eq("chat_completions.is_archived", false)
		.eq("is_archived", false); // ignore archived records

	// If selectedDoctorId is provided, add filter for doctor
	if (selectedDoctorId) {
		query = query.eq("doctor_id", selectedDoctorId);
	}

	// Execute the query
	const { data, error } = await retryWrapper(async () => {
		return await query
	});

	if (error) {
		return { data: [], error: "Error querying patient records list" };
	}

	return { data: data as PatientRecord[] || [], error: null };
};

export const deletePatientRecord = async (id: string): Promise<boolean> => {
	let { error } = await retryWrapper(async () => {
		return await supabase.from("patient_record").update({ id, is_archived: true }).eq("id", id);
	});

	if (error) {
		return false;
	}

	return true;
};

export const deletePatientRecords = async (ids: string[]): Promise<boolean> => {
	for (const id of ids) {
		let { error } = await retryWrapper(async () => {
			return await supabase.from("patient_record").update({ is_archived: true }).eq("id", id);
		});
		if (error) {
			return false;
		}
	}

	return true;
};

// CHAT COMPLETIONS
/**
 * Inserts a chat completion into the database.
 */
export const insertChatCompletion = async (params: {
	userId: any;
	documentType: any;
	completion: any;
	patientRecordId: any;
	transcriptionId: any;
	header: any;
}): Promise<ChatCompletions | null> => {
	let { userId, documentType, completion, patientRecordId, transcriptionId, header } = params;
	let newMessageId = uuidv4();

	let newChatCompletion: any = {
		id: newMessageId,
		account_id: userId,
		chat_json: {
			chat_text: completion,
			header: header,
		},
		transcription_id: transcriptionId,
		document_type_id: documentType?.id,
		type: "audio",
		created_at: new Date().toISOString(),
		role: "assistant",
		patient_record_id: patientRecordId,
		chat_json_original: {
			chat_text: completion,
			header: documentType?.document_type_name,
		},
		header: header,
		status: DocumentStatus.Processing,
		document_snapshot: documentType?.creator_prompt_components,
		is_archived: false,
	};

	const { error } = await retryWrapper(async () => {
		return await supabase.from("chat_completions").upsert(newChatCompletion);
	});

	if (error) {
		return null;
	}

	newChatCompletion.document_types = documentType;

	return newChatCompletion;
};

export const upsertChatCompletions = async (chatCompletions: ChatCompletions): Promise<{ error: string | null }> => {

	const { error } = await retryWrapper(async () => {
		return await supabase.from("chat_completions").upsert(
			{ ...chatCompletions, is_edited: true } as any);
	});

	if (error) {
		return { error: error?.message || "Error upserting chat completions" };
	}

	return { error: null };

};

export const deleteChatCompletions = async (id: string): Promise<boolean> => {
	const { error } = await retryWrapper(async () => {
		return await supabase.from("chat_completions").update({ is_archived: true }).eq("id", id);
	});

	if (error) {
		return false;
	}
	return true;
};

export const updateChatCompletions = async (chatCompletions: ChatCompletions, id: string): Promise<{ error: string | null }> => {
	const { error } = await retryWrapper(async () => {
		return await supabase
			.from("chat_completions")
			.update({
				...chatCompletions as any
			})
			.eq("id", id)
	});

	if (error) {
		//return error function to parent caller
		return { error: error?.message || "Error updating chat completions" };
	}
	return { error: null };
};
export const updateChatCompletionsBulk = async (chatCompletions: ChatCompletions[]): Promise<{ error: string | null }> => {
	const { error } = await retryWrapper(async () => {
		return await supabase.from("chat_completions").upsert([...chatCompletions as any])
	});

	if (error) {
		//return error function to parent caller
		return { error: error?.message || "Error updating chat completions" };
	}
	return { error: null };
};

// TRANSCRIPTIONS
export const upsertTranscription = async (transcript: Transcript): Promise<{ error: string | null }> => {
	const { error } = await retryWrapper(async () => {
		return await supabase.from("transcriptions").upsert({
			...transcript,
		})
	});

	if (error?.code === "23503" && transcript?.patient_record_id) {
		//foreign key error something got hung on the upsertPatientRecord
		const { error: pError } = await retryWrapper(async () => {
			return await supabase.from("patient_record").upsert({
				id: transcript.patient_record_id,
			})
		});

		const { error: tError } = await retryWrapper(async () => {
			return await supabase.from("transcriptions").upsert({
				...transcript,
			})
		});

		if (pError || tError) {
			return { error: pError?.message || tError?.message || "Error upserting transcription" };
		} else {
			return { error: null };
		}
	}
	if (error) {
		return { error: error?.message || "Error upserting transcription" };
	}

	return { error: null };
};

export const setPatientRecordStage = async (id: string, stage: string): Promise<boolean> => {
	const { error } = await retryWrapper(async () => {
		return await supabase
			.from("patient_record")
			.update({
				stage: stage,
			})
			.eq("id", id)
	});

	if (error) {
		return false;
	}

	return true;
};

export const fetchPreviousTranscripts = async (existingTranscripts: Transcript[], numberOfTranscripts: number = 10) => {
	// Fetch the three most recent transcripts
	const { data: transcriptData, error: transcriptError } = await supabase
		.from("transcriptions")
		.select(
			`
            id,
            created_at,
            patient_record_id (
                id,
                patient_name
            )`,
		)
		.not("id", "in", `(${existingTranscripts.map((t) => t?.id)})`)
		.order("created_at", { ascending: false })
		.limit(numberOfTranscripts);

	if (transcriptError) {
		Sentry.captureException(transcriptError)
		return { data: null, error: transcriptError };
	}

	// Fetch transcript sections for each of the above transcripts
	const { data: sectionsData, error: sectionsError } = await supabase
		.from("transcript_sections")
		.select(
			`
            transcription_id,
            data`,
		)
		.in(
			"transcription_id",
			transcriptData.map((t) => t.id),
		);

	if (sectionsError) {
		Sentry.captureException(sectionsError)
		return { data: null, error: sectionsError };
	}

	// Map the transcripts to their sections
	let newTranscripts = transcriptData.map((transcript: any) => {
		const sections = sectionsData
			.filter((section) => section.transcription_id === transcript.id)
			.map((section) => section.data)
			.join("\n");

		return {
			id: transcript.id,
			created_at: transcript.created_at,
			transcript: sections,
			name: transcript?.patient_record_id?.patient_name || "",
		};
	});

	return { data: newTranscripts, error: null };
};

// ACCOUNT DATA
export const fetchAccountData = async (abortSignal: AbortSignal): Promise<Account | null> => {
	const { data, error } = await retryWrapper(async () => {
		return await supabase
			.from("account")
			.select(`
			clinic_name, 
			clinic_location, 
			id, 
			is_self_serve, 
			feature_flags, 
			created_at, 
			meta_data, 
			api_key, 
			current_pims, 
			hidden_documents,
			trial_end_date,
			has_completed_profile
		`)
			.abortSignal(abortSignal)
			.limit(1)
			.single()
	});

	if (error) {
		return null;
	}

	return data
};

export const getDefaultView = async (userId: string): Promise<VisitPage> => {
	const { data, error } = await retryWrapper<{ meta_data: { default_view: VisitPage } }>(async () => {
		return await supabase
			.from("account")
			.select("meta_data")
			.eq("id", userId)
			.single()
	});

	if (error) {
		return VisitPage.List;
	}

	return data?.meta_data?.default_view || VisitPage.List;
}

export const fetchHiddenDocumentsFromAccount = async (abortSignal: AbortSignal): Promise<string[]> => {
	const { data, error } = await retryWrapper<{ hidden_documents: string[] }>(async () => {
		return await supabase
			.from("account")
			.select("hidden_documents")
			.abortSignal(abortSignal)
			.limit(1)
			.single()
	});

	if (error) {
		return [];
	}

	return data?.hidden_documents as string[] || [];
}

// autosaved
export const updateAccountData = async ({ id, clinic_name, clinic_location, has_completed_profile, current_pims }: Partial<Account>) => {
	const { error } = await supabase
		.from("account")
		.update({
			clinic_name,
			clinic_location,
			has_completed_profile,
			current_pims,
		})
		.eq("id", id as string);

	if (error) {
		Sentry.captureException(error)
	}
};

export const updateAccountMetadata = async (id: string, metadata: AccountMetaData) => {
	const { error } = await supabase
		.from("account")
		.update({ meta_data: metadata })
		.eq("id", id);

	if (error) {
		Sentry.captureException(error)
	}
}

export const upsertAddress = async (address: Address): Promise<boolean> => {
	const { error } = await supabase.from("address").upsert({ ...address });

	if (error) {
		Sentry.captureException(error)
		return false
	}

	return true;

};

export const updateAccountHiddenDocs = async ({ id, hidden_documents }: any): Promise<{ error: null | string }> => {
	const { error } = await retryWrapper(async () => {
		return await supabase
			.from("account")
			.update({
				hidden_documents: hidden_documents,
			})
			.eq("id", id)
	});

	if (error) {
		return { error: error?.message || "Error updating hidden documents" };
	}

	return { error: null }
}

export const updateCopyForMarkdown = async (id: string, enableMarkdownCopy: boolean) => {
	if (!id) return { error: "No id provided" };
	const { error } = await retryWrapper(async () => {
		return await supabase
			.from("account")
			.update({ meta_data: { markdown_copy_enabled: enableMarkdownCopy } })
			.eq("id", id)
	});

	if (error) {
		return { error: error?.message || "Unknown error" };
	}

	return { error: null };
};


//ACCOUNT_INTEGRATIONS
export const getAccountIntegrations = async (abortSignal: AbortSignal): Promise<AccountIntegration[]> => {
	const { data, error } = await retryWrapper(async () => {
		return await supabase
			.from("account_integrations")
			.select("integrations(integration_name), id, practice_id, connected_third_party, last_request, api_url, export_enabled, is_active")
			.abortSignal(abortSignal)
	});

	if (error) {
		return []
	}

	if (data && data?.length !== 0) {
		return data as AccountIntegration[];
	}

	return []
};

//TRANSCRIPT SECTIONS
export const upsertTranscriptSection = async (transcriptSection: Omit<TranscriptSection, 'origin'>): Promise<{ error: null | string }> => {
	const withOrigin: TranscriptSection = { ...transcriptSection, origin: window.navigator.userAgent };
	const { error } = await retryWrapper(async () => {
		return await supabase.from("transcript_sections").upsert({
			...withOrigin as any
		})
	});

	if (error) {
		return { error: error?.message || "Error upserting transcription section" };
	}

	return { error: null };

};

export const fetchAllTranscriptSections = async (transcriptId: string): Promise<{ error: null | string, data: TranscriptSection[] }> => {
	const { data, error } = await retryWrapper(async () => {
		return await supabase
			.from("transcript_sections")
			.select(
				`id,
        transcription_id,
        data,
        unprocessed_filename,
        was_removed,
        processed_filename
        `,
			)
			.eq("transcription_id", transcriptId)
			.order("created_at", { ascending: true })
	});

	if (error) {
		return { error: error?.message || "Error Fetching Transcript Sections", data: [] };
	}

	return { error: null, data: data as TranscriptSection[] || [] };
};

export const deleteTranscriptSection = async (transcriptSectionId: string): Promise<{ error: null | string }> => {
	const { error } = await retryWrapper(async () => {
		return await supabase.from("transcript_sections").delete().eq("id", transcriptSectionId)
	});

	if (error) {
		return { error: error?.message || "Error Deleting Transcript Sections" };
	}
	return { error: null };
};

export const deleteAllTranscriptSections = async (transcriptSectionIds: string[]): Promise<{ error: null | string }> => {
	for (let i = 0; i < transcriptSectionIds.length; i++) {
		const { error } = await deleteTranscriptSection(transcriptSectionIds[i]);
		if (error) {
			return { error: error || "Error Deleting Transcript Sections" };
		}
	}
	return { error: null };
};

//STORAGE API
export const isAudioFileInSupabase = async (userId: string, transcriptionId: string, audioName: string) => {
	let fileNameWithoutExtension = audioName.substring(0, audioName.lastIndexOf("."));
	const { data, error } = await supabase.storage.from("audio-storage").list(`${userId}/${transcriptionId}`, {
		search: fileNameWithoutExtension,
	});
	if (error) {
		Sentry.captureException(error)
		return false;
	}
	if (data && data.length > 0) {
		return true;
	} else {
		return false;
	}
};

export const uploadAudioFileToSupabase = async (
	audioFile: any,
	audioName: any,
	tId: any,
) => {
	try {
		const { data: { session }, error: sessionError } = await supabase.auth.getSession();

		if (sessionError || !session) {
			Sentry.captureException(sessionError);
			return { error: true, is409: false };
		}

		const userId = session.user?.id;

		// check if audio already exists, return 409 if it does
		const isAudioInSupabase = await isAudioFileInSupabase(userId || "", tId, audioName);
		if (isAudioInSupabase) {
			return { error: false, is409: true };
		}

		const { error }: any = await supabase.storage
			.from("audio-storage")
			.upload(`${userId}/${tId}/${audioName}`, audioFile);

		if (error) {
			// if error is 401 or 403, refresh session and try again
			if (error?.statusCode === 401 || error?.statusCode === 403) {
				const { error: refreshError } = await supabase.auth.refreshSession();
				if (refreshError) {
					Sentry.captureException(refreshError);
					return { error: true, is409: false };
				} else {
					const { error: retryError }: any = await supabase.storage
						.from("audio-storage")
						.upload(`${userId}/${tId}/${audioName}`, audioFile);

					if (retryError) {
						Sentry.captureException(retryError, {
							extra: { audioName, tId, userId }
						});
						return { error: true, is409: retryError?.statusCode === 409 };
					}
				}
			} else {
				Sentry.captureException(error, {
					extra: { audioName, tId, userId }
				});
				return { error: true, is409: error?.statusCode === 409 };
			}
		}

		return { error: false, is409: false };
	} catch (error) {
		Sentry.captureException(error);
		return { error: true, is409: false };
	}
};

export const uploadToStorage = async (bucket: string, path: string, file: Blob): Promise<SupabaseResponse<string>> => {
	const { data, error } = await supabase.storage.from(bucket).upload(path, file, {
		cacheControl: '3600',
		upsert: true
	});

	if (error) {
		Sentry.captureException(error)
		return { data: null, error: error };
	}

	return { data: data.path, error: null };
}


export const downloadFromStorage = async (bucket: string, path: string): Promise<SupabaseResponse<Blob>> => {
	const { data, error } = await supabase.storage.from(bucket).download(path);

	if (error) {
		Sentry.captureException(error)
		return { data: null, error: error };
	}

	return { data: data, error: null };
}

export const removeFromStorage = async (bucket: string, path: string): Promise<SupabaseResponse<boolean>> => {
	const { error, data } = await supabase.storage.from(bucket).remove([path]);

	if (error) {
		Sentry.captureException(error)
		return { error: error, data: null };
	}

	return { error: null, data: true };
}

export const uploadAndTranscribeBulkAudio = async (
	transcriptionId: string,
	transcriptSectionId: string,
	audioData: Blob,
	audioName: string,
	patientRecordId?: string | null,
	patientName: string = "",
	doctorId: string = "",
	documentIds: string[] = [],
): Promise<{ error: boolean | null }> => {
	try {
		const { error, is409 } = await uploadAudioFileToSupabase(audioData, audioName, transcriptionId);
		//If Error, nothing we can do really.
		if (error && !is409) {
			Sentry.captureException(error);
			return { error: true };
		}

		await upsertTranscriptSection({
			id: transcriptSectionId,
			transcription_id: transcriptionId,
			data: "",
			status: TranscriptSectionStatus.InProgress,
			patient_record_id: patientRecordId || ""
		});

		const { error: transcribeError } = await transcribeAudio(
			transcriptionId,
			transcriptSectionId,
			audioName,
			patientRecordId,
			patientName,
			doctorId,
			documentIds,
		);

		if (transcribeError) {
			Sentry.captureException(transcribeError);
			return { error: true };
		}

		return { error: null };
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

export const downloadAudioFileFromSupabase = async (tId: any, audioName: any) => {
	const supabaseSession = await supabase.auth.getSession();
	const userId = supabaseSession?.data?.session?.user?.id;
	const { data, error } = await supabase.storage.from("audio-storage").download(`${userId}/${tId}/${audioName}`);
	if (error) {
		Sentry.captureException(error)
		return null;
	}
	return data;
};

const moveAudioFile = async (userId: string, tId: string, audioName: string, newTId: string) => {
	// copy then delete the file because move doesn't work thanks supabase
	const { data, error } = await supabase.storage
		.from("audio-storage")
		.copy(`${userId}/${tId}/${audioName}`, `${userId}/${newTId}/${audioName}`);
	if (error) {
		Sentry.captureException(error);
		return { error: true };
	}

	const { error: deleteError } = await supabase.storage.from("audio-storage").remove([`${userId}/${tId}/${audioName}`]);
	if (deleteError) {
		Sentry.captureException(deleteError);
		return { error: null };
	}

	return { error: null };
};

export const moveRecording = async (
	oldTranscriptSectionId: string,
	oldTranscriptionId: string,
	newPatientRecordId: string,
	userId: string,
	audioName: string,
) => {
	const { data, error } = await supabase.rpc("move_recording", {
		old_transcript_section_id: oldTranscriptSectionId,
		new_patient_id: newPatientRecordId,
	});

	const newTranscriptId = data;

	if (error) {
		Sentry.captureException(error);
		return { error: true };
	}

	if (!newTranscriptId) {
		return { error: true };
	}

	const { error: moveAudioError } = await moveAudioFile(userId, oldTranscriptionId, audioName, newTranscriptId);

	return { error: moveAudioError };
};

export const deleteRecording = async (transcriptSectionId: string, userId: string, tId: string, audioName: string) => {
	const { error } = await retryWrapper(async () => {
		return await supabase.from("transcript_sections").delete().eq("id", transcriptSectionId);
	});

	if (error) {
		return true
	}

	// delete the audio file from supabase storage
	const { error: deleteAudioError } = await supabase.storage.from("audio-storage").remove([`${userId}/${tId}/${audioName}`]);

	if (deleteAudioError) {
		Sentry.captureException(deleteAudioError);
		return true;
	}

	return false
};

// IMPORT APPOINTMENT MAPPING
export const getImportMapping = async (): Promise<DataMapping | null> => {
	const { data, error } = await retryWrapper(async () => {
		return await supabase
			.from("data_mappings")
			.select()
			.order("updated_at", { ascending: false })
			.limit(1)
			.maybeSingle()
	});

	if (error || !data) {
		Sentry.captureException(error)
		return null
	}

	return data
}

//GET CUSTOM MODELS
export const getCustomModels = async () => {
	const { data, error } = await supabase.from("custom_models").select("model_name, model_id");
	if (error) {
		Sentry.captureException(error)
	}
	return data;
};

// stripe
export async function getSubscription(abortSignal: AbortSignal): Promise<Subscription | null> {
	const { data: subscription, error } = await retryWrapper(async () => {
		return await supabase
			.from("subscriptions")
			.select("*, prices(*, products(*))")
			.in("status", ["trialing", "active"])
			.abortSignal(abortSignal)
			.maybeSingle()
			.throwOnError()
	});

	if (error) {
		return null;
	}

	return subscription as Subscription | null;
}

export const getActiveProductsWithPrices = async () => {
	const { data, error } = await retryWrapper(async () => {
		return await supabase
			.from("products")
			.select("*, prices(*)")
			.eq("active", true)
			.eq("prices.active", true)
			.eq("display_self_serve", true)
			.order("metadata->index")
			.order("unit_amount", { foreignTable: "prices" })
	});

	if (error) {
		return [];
	}

	return data ?? [];
};

//Prompt Fields
export const getCreatorPromptData = async (getJSON: boolean = false, getMeta: boolean = false, documentId: string | undefined, abortController: AbortSignal) => {
	const sectionType = getJSON ? [SectionType.JSON] : [SectionType.SECTION];
	if (getMeta) {
		sectionType.push(SectionType.META)
	}

	const { data, error } = await retryWrapper(async () => {
		return await supabase
			.from("creator_prompt_fields")
			.select(
				`
				id, 
				field_name, 
				field_description, 
				field_other_names,
				section_type, 
				default_format,
				formatting_options, 
				display_transcript_value,
				response_source,
				hidden,
				display_title_default,
				display_output_default,
				account_id,
				instructions,
				is_archived,
				custom_field_meta (
					custom_prompt,
					document_type_id
				),
				example_prompts (
					id,
					example
				)`,
			)
			.eq("custom_document_type", "SOAP")
			.in("section_type", sectionType)
			.eq("example_prompts.species", "other")
			.filter("is_archived", "eq", false)
			.abortSignal(abortController)
	});

	if (error) {
		return [];
	}

	// get the categories for each field from the creator_prompt_field_categories mapping table
	const { data: creatorPromptFieldCategories, error: categoriesError } = await retryWrapper(async () => {
		return await supabase
			.from("creator_prompt_field_categories")
			.select(`
			category_id, 
			creator_prompt_categories (
				display_name,
				id
			),
			field_id
		`)
			.abortSignal(abortController)
	});

	if (categoriesError) {
		return [];
	}

	// flatten the categories for each map element
	const flattenedCategoryFieldMap = creatorPromptFieldCategories?.map(({ category_id, field_id, creator_prompt_categories }) => ({
		category_id,
		field_id,
		display_name: creator_prompt_categories?.display_name ?? undefined,
	}));

	// attach the mapped categories to the fields
	// transform db -> domain obj here
	const fieldsWithCategories: CreatorPromptField[] = data?.map((field: any) => ({
		...field,
		other_names: field.field_other_names,
		description: field.field_description,
		example: field.example_prompts[0]?.example || '',
		displayOutput: field.display_output_default,
		displayTitle: field.display_title_default,

		// these are all of the custom prompts for the acct
		customPromptOptions: field.custom_field_meta
			.filter((m: { custom_prompt: string; document_type_id: string }) => m.custom_prompt && m.document_type_id !== documentId)
			.map((m: { custom_prompt: string }) => m.custom_prompt) || [],

		// this is the existing custom prompt if the doc id is passed
		customPrompt: field.custom_field_meta.find((m: { document_type_id: string }) => m.document_type_id === documentId)?.custom_prompt || "",

		categories: flattenedCategoryFieldMap
			?.filter(({ field_id }) => field_id === field.id)
			.map(({ category_id, display_name }) => ({
				id: category_id,
				display_name,
			})),
	})) ?? [];

	return fieldsWithCategories;
};

/**
 * (inserts or updates) a new field into the creator_prompt_fields table.
 * @param field - The CreatorPromptField object to be inserted.
 * @returns A Promise that resolves to the inserted CreatorPromptField or null if the insertion fails.
 */
export const upsertField = async (field: CreatorPromptField): Promise<CreatorPromptField | null> => {

	const { data, error } = await retryWrapper(async () => {
		return await supabase
			.from("creator_prompt_fields")
			.upsert(field)
			.select()
	});

	if (error) {
		return null;
	}

	return data?.[0] as CreatorPromptField | null;
}


/**
 * Deletes a field from the creator_prompt_fields table.
 * @param fieldId - The ID of the field to be deleted.
 * @returns A Promise that resolves to true if the deletion is successful, false otherwise.
 */
export const deleteField = async (fieldId: string): Promise<boolean> => {
	const { error } = await retryWrapper(async () => {
		return await supabase
			.from("creator_prompt_fields")
			.delete()
			.eq("id", fieldId)
	});

	if (error) {
		return false;
	}

	return true;
}


/**
 * (inserts or updates) a new example prompt into the example_prompts table.
 * @param examplePrompt - The ExamplePrompt object to be inserted.
 * @returns A Promise that resolves to the inserted ExamplePrompt or null if the insertion fails.
 */
export const upsertExamplePrompt = async (examplePrompt: ExamplePrompt): Promise<ExamplePrompt | null> => {

	const { data, error } = await retryWrapper(async () => {
		return await supabase
			.from("example_prompts")
			.upsert(examplePrompt as any)
			.select();
	});

	if (error) {
		return null;
	}

	return data?.[0] as ExamplePrompt || null;
}

/**
 * Deletes an example prompt from the example_prompts table.
 * @param examplePromptId - The ID of the example prompt to be deleted.
 * @returns A Promise that resolves to true if the deletion is successful, false otherwise.
 */
export const deleteExamplePrompt = async (examplePromptId: string): Promise<boolean> => {
	const { error } = await retryWrapper(async () => {
		return await supabase
			.from("example_prompts")
			.delete()
			.eq("id", examplePromptId)
	});

	if (error) {
		return false;
	}

	return true;

}

/**
 * Inserts a new creator prompt field category mapping into the creator_prompt_field_categories table.
 * @param creatorPromptFieldCategories - The CreatorPromptFieldCategory object to be inserted.
 * @returns A Promise that resolves to the inserted CreatorPromptFieldCategory or null if the insertion fails.
 */
export const insertCreatorPromptFieldCategories = async (creatorPromptFieldCategories: CreatorPromptFieldCategory): Promise<CreatorPromptFieldCategory | null> => {

	const { data, error } = await retryWrapper(async () => {
		return await supabase
			.from("creator_prompt_field_categories")
			.insert(creatorPromptFieldCategories)
			.select();
	});

	if (error) {
		return null;
	}

	return data?.[0] as CreatorPromptFieldCategory || null;
}

/**
 * (inserts or updates) a custom field meta record in the custom_field_meta table.
 * used in autosave
 * @param customFieldMeta - The CustomFieldMeta object to be upserted.
 * @returns A Promise that resolves to the upserted CustomFieldMeta or null if the operation fails.
 */
export const upsertCustomFieldMeta = async (customFieldMeta: CustomFieldMeta): Promise<CustomFieldMeta | null> => {
	try {
		const { data, error } = await supabase
			.from("custom_field_meta")
			.upsert(customFieldMeta as any, {
				onConflict: "account_id,field_id,document_type_id"
			}).select();

		if (error) {
			throw error;
		}

		return data?.[0] as CustomFieldMeta || null;
	} catch (error) {
		Sentry.captureException(error);
		return null;
	}
}

export const getCreatorPromptCategories = async (abortController: AbortSignal) => {

	const { data, error } = await retryWrapper(async () => {
		return await supabase
			.from("creator_prompt_categories")
			.select("id, display_name")
			.abortSignal(abortController)
	});

	if (error) {
		return [];
	}

	return data ?? [];
}

export const insertSectionOrFieldRequest = async (name: string, description: string, exampleOutput: string): Promise<{ error: string | null }> => {
	const { error } = await retryWrapper(async () => {
		return await supabase
			.from("creator_prompt_requests")
			.insert({ request_section_or_field_name: name, request_description: description, example_output: exampleOutput })
	});

	if (error) {
		return { error: error?.message || "Unknown error" };
	}

	return { error: null };
};

export const getCreatorSectionNames = async (abortSignal: AbortSignal): Promise<Set<string>> => {
	const { data, error } = await retryWrapper(async () => {
		return await supabase
			.from("creator_prompt_fields")
			.select("field_name, field_other_names")
			.abortSignal(abortSignal)
	});

	if (error || !data || !Array.isArray(data) || data?.length === 0) {
		return new Set();
	}

	const allNames = data?.reduce((acc: string[], { field_name, field_other_names }) => {
		acc.push(field_name?.toLowerCase() || ''); // Add the section_name
		if (Array.isArray(field_other_names)) {
			// Ensure field_other_names is an array
			let otherNames = field_other_names.map((name: string) => name.toLowerCase());
			acc.push(...otherNames);
		}
		return acc;
	}, []);

	const uniqueNames = new Set(allNames);

	return uniqueNames;
};

// INSIGHTS

/*
* Fetches the insights sharing data for the current account.
* If there are account specific insights, they will be returned.
* If there are no account specific insights, the default insights will be returned.
* @returns an array of insights sharing data
*/
export const getMetricComponents = async (abortSignal: AbortSignal): Promise<InsightsMetric[]> => {

	const { data, error } = await retryWrapper(async () => {
		return await supabase
			.from("insights_sharing")
			.select(`
				target_account_id, 
				insights_metric_id,
				...insights_metrics (
					metric_key,
					name
				)
			`)
			.abortSignal(abortSignal)
	});

	if (error) {
		return [];
	}

	// find account specific metrics
	const accountSpecificMetrics = data?.filter((d: any) => d?.target_account_id !== null) ?? [];

	// if there are account specific metrics, use them without any of the default metrics
	let metricComponents: InsightsMetric[] = [];
	if (accountSpecificMetrics.length > 0) {
		metricComponents = accountSpecificMetrics.map((d: any) => {
			return {
				metricKey: d?.metric_key,
				name: d?.name,
			}
		});
	} else {
		// otherwise, use the default metrics
		const defaultMetrics = data?.filter((d: any) => d?.target_account_id === null) ?? [];
		metricComponents = defaultMetrics.map((d: any) => {
			return {
				metricKey: d?.metric_key,
				name: d?.name,
			}
		});
	}

	return metricComponents;

}


// HELPER FUNCTIONS

const buildFilterFromList = (l: any[]) => {
	let filter = "()";
	if (l.length > 0) {
		filter = "(";
		for (let doc_id of l) {
			filter += `${doc_id},`
		}
		filter = filter.slice(0, -1) + ")";
	}

	return filter;
}



