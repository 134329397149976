import { VITE_HAPPYDOC_INSIGHTS_SERVER_URL } from "@/common/utils/constants";

/*
* Makes a GraphQL request to the HappyDoc Insights server
* @param {string} query - The GraphQL query
* @param {any} variables - The variables to pass to the query
* @param {string} bearerToken - The bearer token to authenticate the request
*/
export async function makeServiceInsightsGraphqlRequest(
    query: string,
    variables: any,
    bearerToken: string,
): Promise<{ data: any | undefined, error: any | undefined }> {

    // define the url for the GraphQL API
    const url = `${VITE_HAPPYDOC_INSIGHTS_SERVER_URL}/api/v1/graphql`;

    // make the request and transform the data
    try {

        // make the request
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${bearerToken}`,
            },
            body: JSON.stringify({
                query: query,
                variables,
            }),
        });

        // handle bad response
        if (!response.ok) {
            return { data: undefined, error: new Error(`HTTP error! status: ${response.status}`) };
        }

        // extract data
        const result = await response.json();
        const data = result?.data;

        // if no data, return an error
        if (!data) {
            return { data: undefined, error: new Error("Invalid response structure from GraphQL API") };
        }

        // return the data
        return { data, error: undefined };
    } catch (error) {
        // handle errors
        return { data: undefined, error };
    }
}

export const RECORDS_GENERATED_QUERY = `
    query ($startRangeTimestamp: String!, $endRangeTimestamp: String!, $selectedDoctorIDs: [String!]!) {
      recordsGenerated(input: { startDate: $startRangeTimestamp, endDate: $endRangeTimestamp, doctorIds: $selectedDoctorIDs }) {
        totalRecordsGenerated
        breakdownByDoctor {
          doctorId
          documentCount
        }
      }
    }
  `;

export const TIME_SAVED_QUERY = `
    query ($startRangeTimestamp: String!, $endRangeTimestamp: String!, $selectedDoctorIDs: [String!]!) {
      timeSaved(input: { startDate: $startRangeTimestamp, endDate: $endRangeTimestamp, doctorIds: $selectedDoctorIDs }) {
        totalTimeSaved
        totalNumberEffectivePatients
        breakdownByDoctor {
          doctorId
          timeSaved
          numberEffectivePatients
          breakdownByDay
        }
      }
    }
`

export const VISITS_SEEN_QUERY = `
    query ($startRangeTimestamp: String!, $endRangeTimestamp: String!, $selectedDoctorIDs: [String!]!) {
        visitsSeen(input: { startDate: $startRangeTimestamp, endDate: $endRangeTimestamp, doctorIds: $selectedDoctorIDs }) {
            totalVisitsSeen,
            totalVisitsScheduled,
            breakdownByDoctor {
                doctorId,
                visitsSeen,
                visitsScheduled
            }
        }
    }
`

export const SENTIMENT_ANALYSIS_QUERY = `
    query ($startRangeTimestamp: String!, $endRangeTimestamp: String!, $selectedDoctorIDs: [String!]!) {
        sentimentAnalysis(input: { startDate: $startRangeTimestamp, endDate: $endRangeTimestamp, doctorIds: $selectedDoctorIDs }) {
            visitSentiment {
                id,
                doctorId,
                ratings {
                    empathy,
                    patience,
                    clarity,
                    efficiency,
                    professional,
                    confidence,
	            },
            },
            sentimentCategories
        }
    }
`

export const TIME_SPENT_IN_VISITS_QUERY = `
query($startRangeTimestamp: String!, $endRangeTimestamp: String!, $selectedDoctorIDs: [String!]!) {
    timeSpentInVisits(input: { startDate: $startRangeTimestamp, endDate: $endRangeTimestamp, doctorIds: $selectedDoctorIDs }) {
            totalTimeSpentInVisits {
            visitType,
                timeSpent
        },
            breakdownByDoctor {
            doctorId,
                timeSpentInVisits {
                visitType,
                    timeSpent
            }
        }
    }
}
`