// third-party libraries
import * as Sentry from "@sentry/react";
import { useSession } from "@supabase/auth-helpers-react";

// react and hooks
import { createContext, useContext, ReactNode, useState, useEffect } from 'react';

// local components
import { DashboardDoctor } from '@features/dashboard/components/common/DoctorMultiSelect';
import { DateRangeOptions, getStartRangeTimestamp, getEndRangeTimestamp } from '@features/dashboard/components/common/DateRangeSelect';
import { getMetricKeyHydrator, InsightsMetric, MetricKeys, MetricComponentsOrder } from '@features/dashboard/components/metrics/MetricComponent';
import { getMetricComponents } from "@/common/lib/supabaseClient";


// MAGIC STRINGS
const LOCAL_STORAGE_SELECTED_DOCTOR_IDS_KEY = "insights:selectedDoctorIds";
const LOCAL_STORAGE_SELECTED_DATE_RANGE_BUTTON_VALUE_KEY = "insights:selectedDateRangeButtonValue";
const LOCAL_STORAGE_METRIC_DATA_KEY = "insights:metricData";

/*
* An interface for the context value of the DashboardStateContext
* This handles everything related to the state of the dashboard's content and selections 
* (but not metric data)
*/
interface DashboardStateContextValue {
    dashboardDoctors: DashboardDoctor[];
    setDashboardDoctors: (doctors: DashboardDoctor[]) => void;
    selectedDoctorIds: string[];
    setSelectedDoctorIds: (doctorIds: string[]) => void;
    selectedDateRangeButtonValue: DateRangeOptions;
    setSelectedDateRangeButtonValue: (value: DateRangeOptions) => void;
    hasAccess: boolean;
    setHasAccess: (hasAccess: boolean) => void;
}

/*
* An interface for the context value of the DashboardMetricsContext
* This handles everything related to the metrics data and keys
*/
interface DashboardMetricsContextValue {
    metricKeys: MetricKeys[];
    metricData: any;

}

/*
* An interface for the value of the DashboardContext
*/
interface DashboardContextValue {
    dashboardStateContext: DashboardStateContextValue;
    dashboardMetricsContext: DashboardMetricsContextValue;
}

// create the context
const DashboardContext = createContext<DashboardContextValue | undefined>(undefined);

// create a hook to use the context
export function useDashboardContext(): DashboardContextValue {
    const context = useContext(DashboardContext);
    if (!context) {
        throw new Error("useDashboardContext must be used within a DashboardProvider");
    }
    return context;
}

/*
* fetchMetricComponents is a helper function that fetches the metric components from the database, 
* and orders them properly
* @returns {Promise<InsightsMetric[]>} - a promise that resolves to an array of InsightsMetric objects
* that represent the metric components
*/
const fetchMetricComponents = async (): Promise<InsightsMetric[]> => {
    // create an abort signal
    const abortSignal = new AbortController().signal;

    // fetch the metric components from db
    const metricComponents = await getMetricComponents(abortSignal);

    // order the metric components using MetricComponentsOrder
    // if a metric is not found, set it to null
    let orderedMetricComponents = MetricComponentsOrder.map((key) => {
        const component = metricComponents.find((metric) => metric.metricKey === key);
        return component || null;
    });

    // filter null values and return
    return orderedMetricComponents.filter((component): component is InsightsMetric => component !== null);
}


/*
* getLocalSelectedDoctorIDs is a helper function that gets the selectedDoctorIds from localStorage
* @returns {string[]} - an array of strings that represent the selected doctor ids
*/
const getLocalSelectedDoctorIDs = (): string[] => {
    const localStorageSelectedDoctorIds = localStorage.getItem(LOCAL_STORAGE_SELECTED_DOCTOR_IDS_KEY);

    let ids: string[] = [];
    try {
        ids = JSON.parse(localStorageSelectedDoctorIds || "[]");
    } catch (e: any) {
        Sentry.captureException("Error getting selectedDoctorIds from localStorage", e);
    }

    return ids;
}

/*
* getLocalSelectedDateRangeButtonValue is a helper function that gets the selectedDateRangeButtonValue from localStorage
* @returns {DateRangeOptions} - a DateRangeOptions value that represents the selected date range button value
*/
const getLocalSelectedDateRangeButtonValue = (): DateRangeOptions => {
    const localStorageSelectedDateRangeButtonValue = localStorage.getItem(LOCAL_STORAGE_SELECTED_DATE_RANGE_BUTTON_VALUE_KEY);

    let value: DateRangeOptions = DateRangeOptions.Days7;
    try {
        if (localStorageSelectedDateRangeButtonValue) {
            value = JSON.parse(localStorageSelectedDateRangeButtonValue);
        }
    } catch (e: any) {
        Sentry.captureException("Error getting selectedDateRangeButtonValue from localStorage", e);
    }

    return value;
}

/*
* getLocalMetricData is a helper function that gets the metricData from localStorage
* @returns {Record<string, { title: string, data: any, loading: boolean }>} - a record of metric data
*/
const getLocalMetricData = (): Record<string, { title: string, data: any, loading: boolean }> => {
    const localStorageMetricData = localStorage.getItem(LOCAL_STORAGE_METRIC_DATA_KEY);

    let data: Record<string, { title: string, data: any, loading: boolean }> = {};
    try {
        if (localStorageMetricData) {
            data = JSON.parse(localStorageMetricData);
        }
    } catch (e: any) {
        Sentry.captureException("Error getting metricData from localStorage", e);
    }

    return data;
}

/*
* cleanupLocalMetricData is a helper function that cleans up the metricData in localStorage
* It removes any metric data that is older than 2 minutes, and deletes the localStorage item if it is invalid
* @returns {void}
*/
const cleanupLocalMetricData = () => {

    // get the metric data from local storage
    const localStorageMetricData = localStorage.getItem(LOCAL_STORAGE_METRIC_DATA_KEY);

    // set threshold for keeping it in local storage cache to 2 minutes
    const persistenceThreshold = 2 * 60 * 1000;

    // check if localStorageMetricData is defined
    if (localStorageMetricData) {
        let metricData: Record<string, { title: string, data: any, loading: boolean }> = {};

        // try to parse the metric data
        try {
            metricData = JSON.parse(localStorageMetricData);
        } catch {
            // if the metric data from local storage is invalid, delete it and return early
            localStorage.removeItem(LOCAL_STORAGE_METRIC_DATA_KEY);
            return;
        }


        const now = new Date().getTime();

        // iterate over the metrics
        for (const key in metricData) {
            // get the data for the metric
            const data = metricData[key].data;

            // iterate over the data keys
            for (const dataKey in data) {

                // extract the end date from the data key
                const endDate = new Date(dataKey.split("_")[2])?.getTime();

                // if the end date is older than the threshold, delete the data
                if (now - endDate > persistenceThreshold) {
                    delete metricData[key].data[dataKey];
                }
            }
        }

        localStorage.setItem(LOCAL_STORAGE_METRIC_DATA_KEY, JSON.stringify(metricData));
    }

}

/*
* The DashboardProvider is a context provider that wraps the entire dashboard page.
* It provides the context for the dashboard's state and metrics data.
*/
export function DashboardProvider({ children }: { children: ReactNode }) {
    // get the session - note that this may have to become injected if this dashboard is hosted outside of the main app
    const session = useSession();

    // DASHBOARD STATE
    const [dashboardDoctors, setDashboardDoctors] = useState<DashboardDoctor[]>([]);
    const [selectedDateRangeButtonValue, setSelectedDateRangeButtonValue] = useState<DateRangeOptions>(getLocalSelectedDateRangeButtonValue());
    const [selectedDoctorIds, setSelectedDoctorIds] = useState<string[]>(getLocalSelectedDoctorIDs());
    const [hasAccess, setHasAccess] = useState(true);

    // save selectedDoctorIds to localStorage when it changes
    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_SELECTED_DOCTOR_IDS_KEY, JSON.stringify(selectedDoctorIds));
    }, [selectedDoctorIds]);

    // save selectedDateRangeButtonValue to localStorage when it changes
    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_SELECTED_DATE_RANGE_BUTTON_VALUE_KEY, JSON.stringify(selectedDateRangeButtonValue));
    }, [selectedDateRangeButtonValue]);


    // DASHBOARD METRICS STATE
    // state for setting which metrics are in the dashboard
    const [metricKeys, setMetricKeys] = useState<MetricKeys[]>([]);

    // updateMetricKeys with data 
    useEffect(() => {

        const fetchComponents = async () => {
            // fetch components from db
            const components = await fetchMetricComponents();

            // set the metric keys
            setMetricKeys(components.map((component) => component.metricKey));
        };

        fetchComponents();
    }, [session, hasAccess]);

    // instantiate the metric data with loading set to true and data set to undefined
    const [metricData, setMetricData] = useState<Record<string, { title: string, data: any, loading: boolean }>>(getLocalMetricData());

    // update metric data in localStorage when it changes
    useEffect(() => {
        // update metric data in local storage
        localStorage.setItem(LOCAL_STORAGE_METRIC_DATA_KEY, JSON.stringify(metricData));

        // cleanup local storage
        cleanupLocalMetricData();
    }, [metricData]);

    // CONTEXT VALUES
    // set the dashboard state context value
    const dashboardStateContextValue: DashboardStateContextValue = {
        dashboardDoctors,
        setDashboardDoctors,
        selectedDoctorIds,
        setSelectedDoctorIds,
        selectedDateRangeButtonValue,
        setSelectedDateRangeButtonValue,
        hasAccess,
        setHasAccess
    }

    // set the metric keys and data in the provider
    const dashboardMetricsContextValue: DashboardMetricsContextValue = {
        metricKeys: metricKeys,
        metricData: metricData,
    }

    // DATA FETCHING
    // state that holds necessary metric retrieval functions
    const [dataRetrievals, setDataRetrievals] = useState<{ name: string, func: (token: string, doctors: DashboardDoctor[], startRangeTimestamp: string, endRangeTimestamp: string) => Promise<any> }[]>([]);

    // update the data retrievals when the metric keys change
    useEffect(() => {
        const fetchDataRetrievals = () => {
            const retrievals = metricKeys.map((key) => {
                return {
                    name: key,
                    func: getMetricKeyHydrator(key)
                }
            });

            setDataRetrievals(retrievals);
        };

        fetchDataRetrievals();
    }, [metricKeys]);

    // hydrate the metric data by calling the hydrator functions and setting loading to false
    useEffect(() => {
        // if there are no data retrievals, return early
        if (!dataRetrievals || dataRetrievals.length === 0 || !selectedDateRangeButtonValue) {
            return;
        }

        // flag to prevent memory leaks
        let isMounted = true;

        // set all metrics to loading state
        setMetricData((prev: any) => {
            const newMetrics = { ...prev };
            dataRetrievals.forEach((retrieval) => {
                newMetrics[retrieval.name] = {
                    ...(prev[retrieval.name] || {}),
                    loading: true,
                };
            });
            return newMetrics;
        });

        // fetch data for each metric
        dataRetrievals.forEach((retrieval) => {
            const fetchData = async () => {
                const requestKey = getDataKey(
                    dashboardDoctors,
                    getStartRangeTimestamp(selectedDateRangeButtonValue),
                    getEndRangeTimestamp(selectedDateRangeButtonValue)
                );

                // skip if data already exists for this requestKey
                if (metricData[retrieval.name]?.data?.[requestKey]) {
                    return;
                }

                try {
                    // fetch the data
                    const data = await retrieval.func(
                        session?.access_token || "",
                        dashboardDoctors,
                        getStartRangeTimestamp(selectedDateRangeButtonValue),
                        getEndRangeTimestamp(selectedDateRangeButtonValue)
                    );

                    // update state incrementally as data resolves
                    if (isMounted) {

                        // update metric data in state
                        setMetricData((prev: any) => ({
                            ...prev,
                            [retrieval.name]: {
                                title: retrieval.name,
                                data: {
                                    ...(prev[retrieval.name]?.data || {}),
                                    [requestKey]: data,
                                },
                                loading: false,
                            },
                        }));
                    }
                } catch (error) {
                    Sentry.captureException(`Error fetching data for ${retrieval.name}: ${error}`);
                }
            };

            // fetch the data
            fetchData();
        });

        // cleanup function
        return () => {
            isMounted = false;
        };
    }, [dataRetrievals, dashboardDoctors, selectedDateRangeButtonValue, session]);

    // return the provider with the context values
    return (
        <DashboardContext.Provider value={{
            dashboardStateContext: dashboardStateContextValue,
            dashboardMetricsContext: dashboardMetricsContextValue,
        }}>
            {children}
        </DashboardContext.Provider>
    );
}

export const getDataKey = (dashboardDoctors: DashboardDoctor[], startRangeTimestamp: string, endRangeTimestamp: string) => `${dashboardDoctors.length}_${startRangeTimestamp}_${endRangeTimestamp}`;
