import isDate from "validator/lib/isDate";
import { AddPatientRequest, CreatorPromptField, ImportFilePayload, ImportFileResponse, PatientRecord, PIMSType, StripePrice } from "../utils/types";
import { Session } from "@supabase/supabase-js";
import { v4 as uuidv4 } from "uuid";
import { retryWrapper, supabase } from "@common/lib/supabaseClient";
import { VITE_HAPPYDOC_TRANSCRIBE_SERVER_URL, VITE_HAPPYDOC_API_SERVER_URL } from "@common/utils/constants";
import * as Sentry from "@sentry/react";


export const transcribeAudio = async (
	transcriptionId: string,
	transcriptSectionId: string,
	audioName: string,
	patientRecordId?: string | null,
	patientName: string = "",
	doctorId: string = "",
	documentIds: string[] = [],
): Promise<{ error: null, data: any } | { error: any, data: null }> => {

	const {
		data: { session },
		error: sessionError,
	} = await supabase.auth.getSession();

	if (sessionError || !session) {
		Sentry.captureException(sessionError);
		return { error: sessionError, data: null };
	}

	const postData = {
		userId: session.user.id,
		transcriptionId,
		transcriptSectionId,
		audioName,
		realtimeClientId: session.user.id,
		patientRecordId,
		patientName,
		doctorId,
		documentIds,
	};

	const url = `${VITE_HAPPYDOC_TRANSCRIBE_SERVER_URL}/transcription/start`;

	const makeRequest = async () => {
		try {
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${session.access_token}`,
				},
				body: JSON.stringify(postData),
			});

			if (!response.ok) {
				const responseText = await response.text();
				throw new Error(`Transcribe Audio Failed: ${response.status} - ${responseText}`);
			}

			const responseData = await response.json();

			if (!responseData.message) {
				throw new Error("No message returned");
			}

			return { error: null, data: responseData };
		} catch (error: any) {
			return { error, data: null };
		}
	};

	const { error, data } = await retryWrapper(makeRequest);

	if (error) {
		return { error, data: null };
	}

	return { error: null, data };
};

export const addTerm = async (session: Session, misspelling: string, term: string, patientId?: string, documentId?: string) => {
	try {
		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/correction/terms`;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
			body: JSON.stringify({
				patientId,
				documentId,
				term,
				misspelling,
				userId: session?.user?.id,
				email: session?.user?.email,
			}),
		});
		return await response.json();
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

export const changeDoctorSortIndex = async (
	doctorId: string,
	sortIndex: number
) => {
	const {
		data: { session },
		error: supaSessionError,
	} = await supabase.auth.getSession();

	if (supaSessionError) {
		throw supaSessionError;
	}

	try {
		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/db/doctors/sortIndex`;

		const response = await fetch(url, {
			method: "PATCH",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
			body: JSON.stringify({
				doctorId,
				sortIndex,
			}),
		});
		return await response.json();
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

/**
 * Send an email to validate a doctor's email
 * @param id doctor id
 * @param email doctor email
 */
export const sendValidateDoctorEmail = async (id: string, email: string) => {
	try {
		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/email/sendValidateEmail`;

		// get session
		const {
			data: { session },
			error: supaSessionError,
		} = await supabase.auth.getSession();

		if (supaSessionError) {
			throw supaSessionError;
		}

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
			body: JSON.stringify({
				email,
				id,
			}),
		});
		return await response.json();
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
}

/**
 * Send a magic link to a doctor to allow them to sign in without a password
 * @param doctorId doctor id
 */
export const sendMagicLink = async (doctorId: string) => {
	try {
		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/email/sendMagicLink`;

		// get session
		const {
			data: { session },
			error: supaSessionError,
		} = await supabase.auth.getSession();

		if (supaSessionError) {
			throw supaSessionError;
		}

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
			body: JSON.stringify({
				id: doctorId,
			}),
		});
		return await response.json();
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
}

/**
 * Retrieves appointments based on the specified criteria.
 *
 * @param userId - The ID of the user.
 * @param queryStartDate - The start date of the query.
 * @param integration_name - An array of integration names.
 * @returns A Promise that resolves to an object containing the retrieved appointments and any errors.
 */
export const getAppointments = async (
	queryStartDate: any,
	integration_name: Array<string>,
): Promise<{ data: PatientRecord[], error: null } | { data: null, error: any }> => {
	try {
		const {
			data: { session },
			error: sessionError,
		} = await supabase.auth.getSession();

		if (sessionError || !session) {
			Sentry.captureException(sessionError);
			return { error: sessionError, data: null };
		}

		// Validate and get timezone offset, else use current date's offset
		let clientTimezoneOffset = isDate(new Date(queryStartDate).toString())
			? new Date(queryStartDate).getTimezoneOffset() * 60000
			: new Date().getTimezoneOffset() * 60000;

		let startDate = new Date(queryStartDate).getTime() - clientTimezoneOffset;
		let startDateObj = new Date(startDate); // Create a Date object from the timestamp
		let startDateISO = startDateObj.toISOString();

		// Create start and end of day in UTC using the Date object
		let startOfDay = new Date(
			Date.UTC(startDateObj.getUTCFullYear(), startDateObj.getUTCMonth(), startDateObj.getUTCDate(), 0, 0, 0, 0),
		);
		let endOfDay = new Date(
			Date.UTC(startDateObj.getUTCFullYear(), startDateObj.getUTCMonth(), startDateObj.getUTCDate(), 23, 59, 59, 999),
		);

		let startOfDayString = startOfDay.toISOString();
		let endOfDayString = endOfDay.toISOString();

		let queryParams = {
			StartTime: startOfDayString,
			EndTime: endOfDayString,
		};

		const makeRequest = async () => {
			try {
				const response = await fetch(
					`${VITE_HAPPYDOC_API_SERVER_URL}/integration/appointments`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							Authorization: `Bearer ${session?.access_token}`,
						},
						body: JSON.stringify({
							userId: session.user.id,
							integrationName: integration_name,
							queryParams: queryParams,
							timezoneOffset: clientTimezoneOffset / 3600000,
						}),
					},
				);

				if (!response.ok) {
					const responseText = await response.text();
					throw new Error(`Get Appointments Failed: ${response.status} - ${responseText}`);
				}
				const responseData = await response.json();

				if (responseData?.errors?.length > 0) {
					throw new Error(
						`Server reported errors: ${JSON.stringify(responseData.errors)}`,
					);
				}

				return {
					data: responseData?.updatedRecords || [],
					error: null,
				};
			} catch (error: any) {
				return { data: null, error };
			}
		};

		// Use retryWrapper with makeRequest
		const { data, error } = await retryWrapper(makeRequest);

		if (error) {
			return { error: error, data: null };
		}

		return { data: data, error: null };
	} catch (error: any) {
		return { error: error, data: null };
	}
};

export const exportRecords = async (
	session: Session | null,
	userId: string,
	chatJson: any,
	patientRecordId: string,
): Promise<any> => {
	try {
		const response = await fetch(`${VITE_HAPPYDOC_API_SERVER_URL}/integration/export`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
			body: JSON.stringify({
				userId: userId,
				chatJson: JSON.stringify(chatJson), // Ensure chatJson is stringified if it's an object
				integrationName: "bitwerx",
				patientRecordId: patientRecordId,
			}),
		});

		if (!response.ok) {
			const responseText = await response.text();
			throw new Error(`Export Records Failed: ${response.status} - ${responseText}`);
		} else {
			return await response.json();
		}
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

export const exportCornerstoneRecord = async (session: Session, userId: string, documentId: string): Promise<any> => {
	try {
		const response = await fetch(`${VITE_HAPPYDOC_API_SERVER_URL}/integration/export-cornerstone`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session.access_token}`,
			},
			body: JSON.stringify({
				userId: userId,
				documentId: documentId,
				integrationName: "bitwerx",
			}),
		});

		if (!response.ok) {
			const responseText = await response.text();
			throw new Error(`Export Document Failed: ${response.status} - ${responseText}`);
		} else {
			return await response.json();
		}
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

export const generateDocument = async (
	session: Session,
	patientRecordId: string,
	documentTypeId: string,
	transcriptionId: string,
	multiplePatientCase: boolean = false,
	patientNameToParse: string = "",
): Promise<{ error: Error | null, response: any | null }> => {
	const url = `${VITE_HAPPYDOC_API_SERVER_URL}/generate/generate`;
	try {
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session.access_token}`,
			},
			body: JSON.stringify({
				document_type_id: documentTypeId,
				transcriptionId: transcriptionId,
				userId: session.user.id,
				patientRecordId: patientRecordId,
				newMessageId: uuidv4(),
				respondAsStream: false,
				multiplePatientCase: multiplePatientCase,
				patientNameToParse: patientNameToParse,
			}),
		});

		if (!response.ok) {
			if (response.status === 403) {
				return { error: new Error("Forbidden: You don't have permission to access this resource."), response: null };
			}

			const responseText = await response.text();
			throw new Error(`Generate Document Failed: ${response.status} - ${responseText}`);
		}

		const data = await response.json();

		return { error: null, response: data };
	} catch (error) {
		if (error instanceof Error) {
			if (error.message === "Load failed" || error.message === "Failed to fetch") {
				return { error: new Error("Network error. Please check your connection."), response: null };
			}
			Sentry.captureException(error);
			return { error, response: null };
		}
		Sentry.captureException(new Error("Unknown error occurred"));
		return { error: new Error("An unknown error occurred."), response: null };
	}
};

// Stripe

export const createCheckoutSession = async (session: Session, price: StripePrice, metaData: {}, redirect: string) => {
	try {
		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/stripe/create-checkout-session`;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session.access_token}`,
			},
			body: JSON.stringify({
				price,
				quanity: 1,
				metaData,
				redirect,
			}),
		});
		return await response.json();
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

//CACHE TEST
export const createStripePortalSession = async (session: Session, redirect: string) => {
	try {
		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/stripe/create-portal-session`;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session.access_token}`,
			},
			body: JSON.stringify({
				redirect,
			}),
		});
		return await response.json();
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

export const newSignUpSlackNotification = async (email?: string, id?: string) => {
	try {
		if (!email || !id) {
			Sentry.captureMessage("Missing required parameters - newSignUpSlackNotification", {
				extra: { email, id },
				level: "error",
			});
			return { error: true };
		}

		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/public/new-signup`;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify({
				email,
				id,
			}),
		});

		return response;
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

export const doctorsModifiedSlackNotification = async (session: Session | null, clinicName: string, doctorName: string, doctorId: string, doctorEmail: string, doctorAdded: boolean) => {
	try {
		if (!session?.user?.id || !session?.access_token || !doctorName || !doctorId || !doctorEmail || doctorAdded === undefined) {
			Sentry.captureMessage("Missing required parameters - doctorsModifiedSlackNotification", {
				extra: { session, clinicName, doctorName, doctorId, doctorEmail, doctorAdded },
				level: "error",
			});
			return { error: true };
		}

		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/slack/doctors-modified`;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
			body: JSON.stringify({
				accountId: session.user.id,
				clinicName,
				doctorName,
				doctorId,
				doctorEmail,
				doctorAdded,
			}),
		});

		return response;
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

export const postNewFeedbackToSlack = async (
	session: Session,
	chat_completion_id?: string,
	feedbackType?: string,
	feedback?: string,
	acct_id?: string,
	doc_name?: string,
	email?: string,
) => {
	try {
		if (!chat_completion_id || !feedbackType || !feedback) {
			Sentry.captureMessage("Missing required parameters - postNewFeedbackToSlack", {
				extra: { chat_completion_id, feedbackType, feedback, acct_id, doc_name, email },
				level: "error",
			});
			return { error: true };
		}

		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/slack/provided-feedback`;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
			body: JSON.stringify({
				chat_completion_id,
				feedbackType,
				feedback,
				acct_id,
				doc_name,
				email,
			}),
		});
		return response;
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

export const newIntegrationRequestSlack = async (session: Session, email?: string, id?: string, pims?: string) => {
	try {
		if (!email || !id || !pims) {
			Sentry.captureMessage("Missing required parameters - newIntegrationRequestSlack", {
				extra: { email, id, pims },
				level: "error",
			});
			return { error: true };
		}

		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/slack/integration-request`;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
			body: JSON.stringify({
				email,
				id,
				pims,
			}),
		});
		return response;
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

/*
 * Send a slack notification for a new builder element request
 * @param session - The current session
 * @param email - The email of the user
 * @param id - The id of the user
 * @param elementName - The name of the element requested
 * @param description - The description of the requested element
 * @returns error: true if the request returns an error
 */
export const newBuilderElementRequestSlack = async (
	session: Session,
	email?: string,
	id?: string,
	elementName?: string,
	description?: string,
	exampleOutput?: string,
) => {
	try {
		if (!email || !id || !elementName || !description || !exampleOutput) {
			Sentry.captureMessage("Missing required parameters - newBuilderElementRequestSlack", {
				extra: {
					email,
					id,
					elementName,
					description,
					exampleOutput,
				},
				level: "error",
			});


			return { error: true };
		}

		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/slack/builder-element-request`;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
			body: JSON.stringify({
				accountId: id,
				email,
				elementName,
				description,
				exampleOutput
			}),
		});
		return response;
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

/**
 * Send a slack notification for a new custom field upsert
 * @param session - The current session
 * @param email - The email of the user
 * @param id - The id of the user
 * @param field - The field that was upserted
 * @returns { error: true } if the request returns an error
 */
export const upsertedCustomFieldSlack = async (session: Session, email?: string, id?: string, isUpdated?: boolean, field?: CreatorPromptField): Promise<{ error: boolean }> => {
	try {
		if (!email || !id || !field) {
			Sentry.captureMessage("Missing required parameters - upsertedCustomFieldSlack", {
				extra: { email, id, field },
				level: "error",
			});
			return { error: true };
		}

		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/slack/custom-field-upsert`;

		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
			body: JSON.stringify({
				accountId: id,
				email,
				field,
				isUpdated
			}),
		});

		if (!response.ok) {
			throw new Error(`Failed to send slack notification - upsertedCustomFieldSlack: ${response.statusText}`);
		}

		return { error: false };
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};

export const invalidateDocumentCache = async (documentId: string) => {
	try {

		// get session
		const {
			data: { session },
			error: supaSessionError,
		} = await supabase.auth.getSession();

		if (!session) {
			Sentry.captureMessage("Session not found - invalidateDocumentCache", {
				extra: { documentId },
				level: "error",
			});
			return;
		}

		if (!documentId) {
			Sentry.captureMessage("Missing required parameters documentId - invalidateDocumentCache");
			return { error: true };
		}

		if (supaSessionError) {
			Sentry.captureException(supaSessionError);
			return;
		}

		const url = `${VITE_HAPPYDOC_API_SERVER_URL}/generate/invalidateCache`;

		await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
			body: JSON.stringify({
				key: documentId,
			}),
		});
	} catch (error: any) {
		Sentry.captureException(error);
		return { error: true };
	}
};


export const addPatient = async (params: AddPatientRequest): Promise<{ error: any, data: PatientRecord | null }> => {
	const url = `${VITE_HAPPYDOC_API_SERVER_URL}/db/addPatient`;

	const {
		data: { session },
		error: sessionError,
	} = await supabase.auth.getSession();

	if (sessionError || !session) {
		Sentry.captureException(sessionError);
		return { error: sessionError, data: null };
	}

	params.userId = session.user.id

	const makeRequest = async () => {
		try {
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${session.access_token}`,
				},
				body: JSON.stringify(params),
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const responseData = await response.json();

			return { error: null, data: responseData };
		} catch (error: any) {
			return { error, data: null };
		}
	};

	const { error, data } = await retryWrapper(makeRequest);

	if (error) {
		return { error, data: null };
	}

	return { error: null, data };
};

// export const search = async (SearchParams: SearchParams): Promise<{ error: any, data: any[] | null }> => {

export const search = async (query: string): Promise<{ error: any, data: PatientRecord[] | null }> => {
	const url = `${VITE_HAPPYDOC_API_SERVER_URL}/search/searchPatients`;

	const {
		data: { session },
		error: sessionError,
	} = await supabase.auth.getSession();

	if (sessionError || !session) {
		Sentry.captureException(sessionError);
		return { error: sessionError, data: null };
	}

	const makeRequest = async () => {
		try {
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${session.access_token}`,
				},
				body: JSON.stringify({
					userId: session.user.id,
					query,
				}),
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const responseData = await response.json();

			return { error: null, data: responseData };
		} catch (error: any) {
			return { error, data: null };
		}
	};

	const { error, data } = await makeRequest();

	if (error) {
		return { error, data: null };
	}

	return { error: null, data };
}

export const syncCollections = async (): Promise<{ error: any, data: any[] | null }> => {
	const url = `${VITE_HAPPYDOC_API_SERVER_URL}/search/syncCollections`;

	const {
		data: { session },
		error: sessionError,
	} = await supabase.auth.getSession();

	if (sessionError || !session) {
		Sentry.captureException(sessionError);
		return { error: sessionError, data: null };
	}

	const makeRequest = async () => {
		try {
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${session.access_token}`,
				},
				body: JSON.stringify({
					userId: session.user.id,
				}),
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const responseData = await response.json();

			return { error: null, data: responseData };
		} catch (error: any) {
			return { error, data: null };
		}
	};

	const { error, data } = await makeRequest();

	if (error) {
		return { error, data: null };
	}

	return { error: null, data };
}



/**
 * Creates an export document of the specified type.
 *
 * This function sends a POST request to the HappyDoc API server to create an export document.
 * It retrieves the current session from Supabase authentication and includes the session token
 * in the request headers for authorization.
 *
 * @param {string} documentTypeId - The ID of the document type to be exported.
 * @returns {Promise<{ error: any; data: any }>} - A promise that resolves to an object containing
 * the response data or an error.
 *
 * @throws Will capture and return any errors encountered during the process using Sentry.
 */
export const createExportDocument = async (documentTypeId: string) => {
	const url = `${VITE_HAPPYDOC_API_SERVER_URL}/integration/export/create`;

	const {
		data: { session },
		error: sessionError,
	} = await supabase.auth.getSession();

	if (sessionError || !session) {
		Sentry.captureException(sessionError);
		return { error: sessionError, data: null };
	}

	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: `Bearer ${session?.access_token}`,
		},
		body: JSON.stringify({
			documentTypeId
		}),
	});

	if (response.status > 299) {
		const responseText = await response.json();
		throw new Error(`${responseText?.error || "Failed to create export document"}`);
	}

	return await response.json();
};

/**
 * Changes the PIMS (Property Information Management System) by making a POST request to the specified API endpoint.
 *
 * @param {PIMSType} newPIMS - The new PIMS data to be sent in the request body.
 * @returns {Promise<{ error: any | null, data: any | null }>} - A promise that resolves to an object containing either the error or the data from the response.
 *
 * @throws Will throw an error if the session retrieval fails or if the fetch request fails.
 */
export const changePIMS = async (newPIMS: PIMSType): Promise<{ error: any | null, data: any | null }> => {
	const url = `${VITE_HAPPYDOC_API_SERVER_URL}/db/changePIMS`;

	const {
		data: { session },
		error: sessionError,
	} = await supabase.auth.getSession();

	if (sessionError || !session) {
		Sentry.captureException(sessionError);
		return { error: sessionError, data: null };
	}

	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: `Bearer ${session?.access_token}`,
		},
		body: JSON.stringify({
			newPIMS,
			accountId: session.user.id,
		}),
	});

	if (response.status > 299) {
		const responseText = await response.json();
		return { error: responseText?.error || "Failed to change PIMS", data: null };
	}

	const responseData = await response.json();

	return { error: null, data: responseData };
}

export const importAppointmentsFile = async (payload: ImportFilePayload, processRecords: boolean): Promise<{ data: ImportFileResponse | null, error: null } | { data: null, error: any }> => {
	const url = `${VITE_HAPPYDOC_API_SERVER_URL}/v2/integration/import-file?process_records=${processRecords}`;

	const {
		data: { session },
		error: sessionError,
	} = await supabase.auth.getSession();

	if (sessionError || !session) {
		Sentry.captureException(sessionError);
		return { error: sessionError, data: null };
	}

	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: `Bearer ${session?.access_token}`,
		},
		body: JSON.stringify(payload),
	});

	if (response.status > 299) {
		const responseText = await response.json();
		return { error: responseText?.error || "Failed to import appointments", data: null };
	}

	const responseData: ImportFileResponse = await response.json();

	return { error: null, data: responseData };
}

export const requestInsightsAccess = async () => {
	const url = `${VITE_HAPPYDOC_API_SERVER_URL}/db/requestInsightsAccess`;

	const {
		data: { session },
		error: sessionError,
	} = await supabase.auth.getSession();

	if (sessionError || !session) {
		Sentry.captureException(sessionError);
		return { error: sessionError };
	}

	try {
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
		});

		if (response.status > 299) {
			const responseText = await response.json();
			return { error: responseText?.error || "Failed to request insights access" };
		}
	} catch (error: any) {
		Sentry.captureException(error);
		return { error };
	}



	return { error: null };
}
