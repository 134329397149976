import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/app.tsx";
import "./app/index.css";
import "react-toastify/dist/ReactToastify.css";
import 'react-tooltip/dist/react-tooltip.css'
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import { supabase } from "./common/lib/supabaseClient.ts";
import { DataContextProvider } from "./state/providers/DataProvider.tsx";
import { IntercomManager } from "./common/lib/Intercom.tsx";
import { RealTimeContextProvider } from "./state/providers/RealtimeProvider.tsx";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";
import store from "./app/store.ts";
import { Provider } from "react-redux";
import { AccountProvider } from "@providers/AccountProvider.tsx";
import { VITE_PUBLIC_ENV, VITE_SENTRY_SESSION_SAMPLE_RATE, VITE_MUI_PRO_KEY } from "@common/utils/constants";
import { LicenseInfo } from '@mui/x-license';

// Any license errors will be logged to the console
LicenseInfo.setLicenseKey(VITE_MUI_PRO_KEY);

// check if the environment is live (production or preview)
const isLiveEnv = VITE_PUBLIC_ENV && (VITE_PUBLIC_ENV === "production" || VITE_PUBLIC_ENV === "preview")
// set the session sample rate to what is defined in the environment variable else 0.1
const sentrySessionSampleRate = VITE_SENTRY_SESSION_SAMPLE_RATE ? parseFloat(VITE_SENTRY_SESSION_SAMPLE_RATE) : 0.1

const errorCooldowns = new Map(); // Store user error timestamps

/**
 * Determines whether an error message should be sent based on the user ID and error message.
 * Implements a cooldown mechanism to prevent sending the same error message for the same user
 * within a 10-minute window.
 *
 * @param userId - The ID of the user. Can be a string, number, or undefined.
 * @param errorMessage - The error message to be sent. Can be a string or undefined.
 * @returns `true` if the error message should be sent, `false` otherwise.
 */
function shouldSendError(userId: string | number | undefined, errorMessage: string | undefined) {
	// if either the user ID or error message is missing, don't send the error
	if (!userId || !errorMessage) {
		return false;
	}

	const key = `${userId}:${errorMessage}`;
	const lastErrorTime = errorCooldowns.get(key);
	const now = Date.now();

	// error happened in the last 10 minutes
	if (lastErrorTime && now - lastErrorTime < 600000) { // 10 minutes
		return false;
	}

	errorCooldowns.set(key, now);
	return true;
}

try {

	Sentry.init({
		dsn: "https://6a322cf643e49d5bd1d053b710a8aae5@o4506666677239808.ingest.sentry.io/4506667695931392",
		// replaysSessionSampleRate: isLiveEnv ? sentrySessionSampleRate : 0,
		// replaysOnErrorSampleRate: isLiveEnv ? 1.0 : 0, // 100% of sessions
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 0,

		//debug: true,
		integrations: [
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect: React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
			Sentry.replayIntegration({ maskAllText: false, maskAllInputs: false }),
			Sentry.captureConsoleIntegration(),
		],
		environment: VITE_PUBLIC_ENV,

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		tracesSampleRate: 1.0,

		// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
		tracePropagationTargets:
			isLiveEnv
				? [
					/^https:\/\/service.gohappydoc.com\/api-server/,
					/^https:\/\/service.gohappydoc.com\/service-transcribe/,
					/^https:\/\/kezqvoudwepwufpjcjkk.supabase.co/,
					/^https:\/\/supa.happydoc.ai/,
				]
				: [
					"localhost",
					/^https:\/\/test.gohappydoc.com\/api-server/,
					/^https:\/\/test.gohappydoc.com\/service-transcribe/,
					/^https:\/\/ntelvrlfzbgggnlgnzup.supabase.co/,
				],
		beforeSend: (event, hint) => {
			// log to console if env === development
			if (!isLiveEnv) {
				console.error(hint.originalException);
				return null
				// console.error(hint.originalException, event.extra); // if you want the extra stuff too
			}

			// filter out spammy errors
			if (event.user && event.exception) {
				const userId = event.user.id;
				const errorMessage = event?.exception?.values && event.exception.values[0]?.value;

				if (!shouldSendError(userId, errorMessage)) {
					return null; // Prevent sending duplicate errors
				}
			}

			return event;
		},
		beforeBreadcrumb: (breadcrumb) => {
			// log to console if env === development
			if (process.env.VITE_PUBLIC_ENV === "development") {
				console.log(breadcrumb);
			}

			return breadcrumb;
		},
		enabled: isLiveEnv,
	});
} catch (e) {
	console.error("Error initializing Sentry", e);
}

ReactDOM.createRoot(document.getElementById("root")!).render(
	<React.StrictMode>
		<Provider store={store}>
			<SessionContextProvider supabaseClient={supabase}>
				<IntercomManager />
				<AccountProvider>
					<DataContextProvider>

						<RealTimeContextProvider>
							<div className="min-h-screen bg-gray-100 text-gray-900">
								<App />
							</div>
						</RealTimeContextProvider>
					</DataContextProvider>
				</AccountProvider>
				<ToastContainer />
			</SessionContextProvider>
		</Provider>
	</React.StrictMode>,
);
